.membershipServices {
  /* position: relative; */width: 100%;
}
.background h3 {
  margin: 0;
  text-align: center;
  color: #1c1c1c;
  font-size: 35px;
  /* font-family: Montserrat; */
  font-weight: 600;
}
.background p {
  text-align: center;
  color: #000;
  text-align: center;
  font-size: 22px;
  /* font-family: Montserrat; */
}

.background button {
  text-align: center;
  color: #fff;
  text-align: center;
  font-size: 15px;
  font-family: Montserrat;
  font-weight: 500;
}
.background {
  /* position: absolute; */
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  row-gap: 1rem;
  background-image: url(../../assets/images/secondBanner.png);
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 540px;
}
.acceptButton {
  padding: 11px 20px;
  background-color: rgba(33, 111, 185, 1);
  transition-duration: 0.2s;
  border: none;
  color: rgb(241, 241, 241);
  cursor: pointer;
  font-weight: 600;
  z-index: 2;
  /* border: 1px solid rgb(255, 255, 255); */
  border-radius: 20px;
} 
@media only screen and (max-width: 767px) and (min-width: 320px) {
  .background{
    height: 310px;
  }
  .background > h3{
      font-size: 20px;
  }
  .background p{
    font-size: 14px;
  }

}