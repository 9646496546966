.mainContainer {
  width: 90%;
  margin: 0 auto;
}
.topHeadings {
  /* width: 40%; */
  display: flex;
  justify-content: space-between;
  margin: 30px auto;
  /* text-align: center; */
}
.leftsideInfobox{
    width: 50%;
}
.rightSideinfo{
    width: 40%;
}
.headingImgContainer {
  width: 30%;
  margin: 0 auto;
}
.headingImgContainer > img {
  width: 100%;
  margin: 0 auto;
}
.Heading{
    font-size: 35px;
    margin-bottom: 10px;
    color: #216fb9;
}
.PointerHeading{
    color: #216fb9;
}
.pointerList > li{
    list-style: disc;
    margin-left: 40px;
    text-align: left;
}
.hireHeading{
    font-size: 30px;
    color: #216fb9;
    margin-bottom: 10px;
}
.joblistingHeading {
  display: flex;
  gap: 30px;
}

.joblistingHeading > p {
  width: 40%;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 700;
}
.jobCard {
  display: flex;
  align-items: baseline;
  border-bottom: 3px solid rgba(0, 0, 0, 0.1);
  /* box-shadow: 0 8px 6px -6px  rgba(0, 0, 0, 0.2); */
  margin-bottom: 10px;
  gap: 30px;
}
.jobDesc {
  width: 40%;
}
.jobTitle {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 10px;
}

.jobExp {
  margin: 10px 0;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
}

.timePosted {
  color: rgba(0, 0, 0, 0.6);
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 10px;
  width: 80%;
}
.locationType {
  color: rgba(0, 0, 0, 0.6);
  text-transform: uppercase;
  font-weight: 600;
  width: 30%;
}
.jobDescBtn {
  color: #fff;
  background-color: #216fb9;
  border: none;
  width: 30%;
  border-radius: 5px;
  padding: 10px;
  font: 20px;
  height: fit-content;
}

.submitBtn {
  color: #fff;
  background-color: #216fb9;
  border: none;
  width: 30%;
  border-radius: 5px;
  padding: 10px;
  font-size: 18px;
  /* text-align: center; */
  /* height: fit-content; */
}

input[type="file"] {
  display: none;
}

.fileName {
  cursor: pointer;
  border: 1px dashed rgba(0, 0, 0, 0.5);
  width: 100%;
  display: inline-block;
  padding: 15px;
  margin: 10px 0;
  box-sizing: border-box;
}

.fromapplydata {
  background-color: #216fb9;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  width: 20%;
}
.fromerrorMessage{
    color: red;
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
  .modelMobile {
    width: 275px !important;
    padding: 20px !important;
  }

  .topHeadings {
    width: 100%;
    flex-direction: column-reverse;
  }
  .rightSideinfo{
    width: 100%;
  }
  .leftsideInfobox{
    width: 100%;
  }
  .jobCard{
    display: block;
  }
  .joblistingHeading{
    display: none;
  }
  .jobDesc{
    width: 100%;
  }
  .timePosted{
    width: 100%;
  }
  .locationType{
    width: 100%;
  }
  .fromapplydata{
    width: 100%;
    margin: 20px 0;
  }
}
