.bannerContainer {
  background-image: url(../../assets/images/ExcellenceCentre.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 980px;
  display: flex;
  align-items: center;
}

.bannerText {
  padding-left: 60px;
}

.bannerText > p {
  font-size: 18px;
  font-weight: 700;
  color: #606060;
}
.bannerText > h1 {
  font-size: 40px;
  font-weight: 700;
  color: #000;
}

.bannerText > button {
  font-size: 18px;
  color: #fff;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 30px;
  padding: 10px;
}
.containerHeading {
  text-align: center;
  font-size: 35px;
  font-weight: 700;
  color: #000;
}
.cardContainer {
  display: flex;
  justify-content: space-between;
  /* width: 1400px; */
  margin: 0 auto;
}

.cards {
  width: 30%;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  text-align: center;
}
.title {
  font-size: 35px;
  font-weight: 500;
  color: #000;
}
.Container {
  margin: 50px;
}
.innerContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.leftSide {
  width: 35%;
}
.rightSide {
  width: 60%;
}
.abCardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.abcards {
  width: 40%;
  text-align: center;
}
.leftSide > p {
  font-size: 20px;
  font-weight: 400;
  text-align: justify;
  margin-bottom: 20px;
}
.heading {
  font-size: 35px;
  font-weight: 700;
  color: #000;
}

.heading > span {
  color: #216fb9;
}

.customCarouselItem {
  /* margin-right: 20px; Adjust the value as needed */
  /* width: 560px !important; */
  /* gap: 20px; */
}
.researchContainer{
  background-image: url(../../assets/images/Researchfacilty.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 406px;
  display: flex;
  align-items: center;
  
}
.researchContainer > div{
  padding-left: 40px;
}
.heading{
  font-size: 18px;
  font-weight: 700;
  color: #000;
}
.tagLine{
  font-size: 25px;
  font-weight: 700;
  color: #000;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .bannerContainer {
    background-image: url(../../assets/images/excpageMobile.png);
    height: 582px;
  }

  .bannerText {
    padding-left: 20px;
  }

  .bannerText > p {
    font-size: 12px;
  }
  .bannerText > h1 {
    font-size: 23px;
  }

  .bannerText > button {
    font-size: 12px;
    border-radius: 30px;
    padding: 5px;
  }
  .Container {
    margin: 50px 25px;
  }
  .cardContainer {
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .cards {
    width: 148px;
  }
  .imgContainer {
    width: 75px;
    margin: 10px auto;
  }
  .imgContainer > img {
    width: 100%;
  }
  .title {
    font-size: 16px;
  }
  .innerContainer {
    display: block;
  }

  .leftSide,
  .rightSide {
    width: 100%;
  }
  .heading {
    font-size: 20px;
  }
  .leftSide > p {
    font-size: 14px;
  }
  .abcards{
    width: 150px;
  }
  .abimgContainer {
    width: 75px;
    text-align: center;
    margin: 0 auto;
  }
  .abimgContainer > img {
    width: 100%;
  }
  .abtitle{
    font-size: 14px;
    font-weight: 600;
    color: #000;
  }
  .containerHeading{
    font-size: 20px;
    font-weight: 700;
    color: #000;
  }
  .researchContainer{
    text-align: center;
    position: relative;
  }
  .researchContainer::after{
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color:  rgba(255, 255, 255, 0.45);
    z-index: 0;
  }
  .researchContainer > div{
    padding-left: 0px;
    /* background-color: rgba(255, 255, 255, 0.25);
    height: fit-content; */
    z-index: 1;
  }
  .researchContainer div > h4{
    font-size: 14px;
   
  }
  .tagLine{
    font-size: 20px;
    
  }
}
