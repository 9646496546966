.slickstyles {
    width: 80%;
    margin: 0 auto;
  box-sizing: border-box;
  /* width: 0 auto; */
}
.container{
    padding: 30px 0px;
    width: 100%;
    margin: auto;
}