.background {
  position: relative;
  /* background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column; */
  /* row-gap: 1rem; */
  background-image: url(../../assets/images/image_13.png);
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 800px;
}

.bannerText {
  position: absolute;
  top: 60%;
  left: 35%;
}

.bannerText > h1 {
  margin: 0;
  text-align: center;
  color: #1c1c1c;
  font-size: 54px;
  font-family: Montserrat;
  font-weight: 600;
}
.bannerText > p {
  text-align: center;
  color: #000;
  text-align: center;
  font-size: 27px;
  font-family: Montserrat;
}

.background > button {
  text-align: center;
  color: #fff;
  text-align: center;
  font-size: 15px;
  font-family: Montserrat;
  font-weight: 500;
}

.product_title > h2,
.request_inquiry > h2 {
  color: #131313;
  text-align: center;
  font-size: 45px;
  font-weight: 500;
}
.mt2 {
  margin-top: 20px;
}
.mt4 {
  margin-top: 40px;
}

.mb2 {
  margin-bottom: 20px;
}
.mb4 {
  margin-bottom: 40px;
}
.login_title > h1 {
  color: #000;
  text-align: center;
  font-size: 35px;
  font-family: Montserrat;
  font-weight: 700;
}

.login_title > p {
  color: #000;
  text-align: center;
  font-size: 17px;
}

.d_flex {
  display: flex ;
  flex-wrap: wrap;
  gap:40px;
  justify-content: center;
}


@media only screen and (max-width: 767px) and (min-width: 320px) {
  .background {
    background-image: url(../../assets/images/image_13.png);
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 315px;
  }
  .bannerText {
    position: absolute;
    top: 60%;
    left: -40px;
    transform: translate(50%, 50%);
  }
  .bannerText > h1 {
    margin: 0;
    text-align: center;
    color: #1c1c1c;
    font-size: 20px;

    font-weight: 600;
  }
  .bannerText > p {
    text-align: center;
    color: #000;
    text-align: center;
    font-size: 15px;
  }
  .product_title > h2,
  .request_inquiry > h2 {
    font-size: 20px;
  }
  .d_flex {
    display: flex ;
    /* gap:2rem; */
    flex-wrap: wrap;
    justify-content: center;
  }
}
