.WhishlistBox {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  padding: 20px 15px;
  box-sizing: border-box;
  border-radius: 5px;
}

.heading {
  font-size: 20px;
  font-weight: 600;
  color: #292d32;
}
.WhishListContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  padding: 10px;
  box-sizing: border-box;
}
.wlimg {
  width: 20%;
}
.wlimg > img {
  width: 100%;
}
.wldetails {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}
.wlname {
  margin: 10px 0;
  padding-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
  color: #000;
}
.wlPrice {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
.batchTime {
  font-size: 14px;
  font-weight: 400;
  color: #000;
}
.wlPrice,
.batchTime {
  padding-bottom: 10px;
}

.quntntityBTN {
  width: 20%;
}
.btnsGroup {
  width: 20%;
}
.dayslist {
  display: flex;
  /* justify-content: space-between; */
}
.days {
  position: relative;
  width: 50px;
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

.days:not(:last-child)::after {
  content: '|';
  position: absolute;
  top: -2px;
  right: 5px;
  color: black;
}

.incdecBtn {
  display: flex;
  background-color: #216fb9;
  height: 30px;
  align-items: center;
  width: 100px;
  box-sizing: border-box;
  padding: 5px;
  justify-content: space-between;
  border-radius: 50px;
  color: #fff;
}
.incdecBtn > h5 {
  font-size: 18px;
  font-weight: 400;
}
.PMBtn {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 22px;
}

.btnsGroup {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.buyBtn {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background-color: #216fb9;
  border-radius: 50px;
  border: none;
  margin-bottom: 30px;
  padding: 15px 0;
}

.removeBtn {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  background-color: #fff;
  border-radius: 50px;
  border: 1px solid #000;
  padding: 15px 0;
}

.onlineBatch {
  color: #fc8a00;
  background-color: rgba(252, 138, 0, 0.12);
  border: 1px solid #fc8a00;
  border-radius: 50px;
  width: 60%;
  padding: 5px 0;
  margin: 10px 0 0;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
}

.offlineBatch {
  color: #216fb9;
  background-color: #e5f9ff;
  border: 1px solid #216fb9;
  border-radius: 50px;
  width: 60%;
  padding: 5px 0;
  margin: 10px 0 0;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .WhishlistBox {
    padding: 0;
    box-shadow: none;
    border-radius: 0;
  }
  .WhishListContainer {
    flex-direction: column;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }
  .wlimg {
    width: 100%;
  }
  .wldetails {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .quntntityBTN {
    width: 100%;
  }
  .btnsGroup {
    width: 100%;
  }
  .mobileShow {
  }
  .PMBtn {
    background-color: #000;
    border-radius: 50px;
    width: 30px;
  }
  .incdecBtn {
    padding: 0;
    margin: 20px 0;
  }
  .onlineBatch,
  .offlineBatch {
    width: 35%;
  }
}
