.slickstyles {
  width: 80%;
  margin: 0 auto;
  box-sizing: border-box;
  /* width: 0 auto; */
}
.container {
  /* background-color: #80caff12; */
  padding: 50px 0;
}
.head {
  width: 100%;
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  color: #000000;

  & span {
    font-size: 32px;
    font-weight: 800;
    color: #216fb9;
  }
}
@media screen and (max-width: 768px) and (min-width: 320px) {
 

  .head{
    font-size: 20px;
  
    & span{
      font-size: 20px;
    }
  }
  }