header{
    display: flex;
    justify-content: space-between;
    padding: 20px 40px;
    align-items: center;
    /* position: absolute; */
    z-index: 1;
}
.active{
  
}
.topnavtextScroll{
    background: #216fb9;
}
.callUs{
    text-decoration: none;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    padding: 5px 0;
}
.siteLogo{
    width: 10%;
}
.siteLogo img {
    width: 100%;
}
.rightNav{
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.headerNavigation{
    width: 70%;
}
.headerNavigation > ul{
    display: flex;    
    justify-content: flex-end;
    gap: 30px;
    /* position: relative; */
}
.headerNavigation > ul > li > a {
    color: #000;
    display: inline-block;
    cursor: pointer;
    font-size: 21px;
    font-weight: 600;
}
.headerNavigation {
     & ul{
        & li{
            
            text-align: initial;
        }
     }
}
.headerNavigation > ul > li > a.active {
    color: #216FB9;
}
.searchProfileBox{
    display: flex;
    width: 20%;
    justify-content: flex-end;
    gap: 30px;
}

.navSearch{
    width: 15%;
}
.navSearch img{
    width: 100%;
}

.profilebox{
    width:20%;
}
.profilebox img{
    width: 100%;
}
.rightNavMobile{
    display: none;
}

.submenubox{
    display: none;
    position: absolute;
    background-color: #fff;
    z-index: 10;
}

.submenulist{
& a{
    color: #000;
    display: inline-block;
    cursor: pointer;
    white-space: nowrap;
    padding: 10px;
    font-size: 20px;
}
}
.headerNavigation {
    & ul{
       & li:hover{
        & .submenubox{
            display: block;
        }
       }
    }
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
    header{
        padding: 20px;
    }
    .siteLogo{
        width: 30%;
    }
    .rightNav{
        display: none;
    }
    .rightNavMobile{
        display: block;
        display: flex;
        gap: 10px;
        align-items: center;
    }
    .mobileLoginbtn{
        background-color: #216FB9;
        border: none;
        color: #fff;
        padding: 10px 20px;
        border-radius: 10px;
    }

    .burgermenu{

    }
    .burgermenu span{
        display: block;
        width: 40px;
        height: 5px;
        background-color: #000;
        margin-bottom:3px;
        /* gap: 3px; */
    }
    .mlogoImg{
        width: 100px;

    }
    .mlogoImg > img{
        width: 100%;
    }
    .mlogoClose{
        display: flex;
        justify-content: space-between;
        margin: 20px;
    }
}