.container {
  width: 90%;
  margin: 0 auto;
  position: relative;
}

.head {
  border-bottom: 1px solid #216fb9;
  padding-bottom: 10px;
  font-size: 26px;
  font-weight: 600;
}
.productPrice {
  display: flex;
  margin: 50px auto;
  justify-content: space-between;
  position: relative;
}
.BackNavigate{
  display: flex;
}
@media screen and (max-width: 768px) and (min-width: 320px) {
  .productPrice {
    flex-direction: column;
  }
  .head{
    font-size: 16px;
  }
  .productPrice{
    margin: 20px auto;
  }
}
