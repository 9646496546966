.courseCard{
    display: flex;
    justify-content: space-around;
    border: 1px solid #FEA800;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    align-items: center;
    margin-bottom: 20px;
}
.leftOuterContainer{
    width: 80%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.leftContainer{
    width: 35%;

    & img{
        width: 100%;
        border-radius: 25px;
        max-height: 160px;
    }
}


.Centerdetils{
    width: 60%;
}

.title{
    font-size: 20px;
    margin: 5px 0;
}

.desc{
    font-size: 16px;
    width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  & p {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & ul{
     & li{
        width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
     }
  }
}

.locationLang{
    font-size: 12px;
    display: flex;
    gap:20px
}
.locations, .language{
    font-size: 12px;
    display: flex;
    gap: 5px;
    align-items: center;
}

.mobilecenter{
    display: none;
}

.rightContainer{
    width: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.status{
    display: inline-block;
    padding: 5px 10px;
    border-radius: 10px;
}
.online{
    background-color: #398415;
    color: #fff;
}
.offline{
    background-color: #0083FF;
    color: #fff;
}

.hybrid{
    background-color: #6F00FC;
    color: #fff;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
    .courseCard{
        display: block;
        padding: 5;
    }
    .leftOuterContainer{
        width: 100%;
    }

    .leftContainer{

        & img {
            height: 100%;
            border-radius: 10px;
        }
    }
    .title{
        font-size: 16px;
    }
    .desc{
        font-size: 12px;
    }

    .locationLang{
        display: none;
    }

    .ratingwitCta{
        display: none;
    }

    .mobilecenter{
        display: flex;
        justify-content: space-between;
        /* align-items: center; */
        margin: 10px 0;
    }

    .ratingCtype{

        & h4{
            margin: 5px 0;
        }
    }
    .coursetypetext{
        font-size: 12px;
        background-color: #0083FF;
        color: #fff;
        padding: 5px;
        border-radius: 10px;
    }

    .rightContainer{
        width: 100%;
        flex-direction: row;
    }
    .status{
        margin: 5px 0;
    }
}

