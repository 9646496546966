.pilotsDetailsContainer {
  /* padding: 150px 0; */
  width: 1400px;
  margin: 0 auto 100px;
}
.pilotsProfile {
  margin-top: 20px;
  display: flex;
  gap: 30px;
}
.pilotPic {
  width: 25%;
}

.pilotPic img {
  width: 100%;
}
.pilotDesc {
  width: 55%;
}
.pilotName {
  font-size: 30px;
  font-weight: 700;
}
.pilotLicence {
  font-size: 18px;
  font-weight: 400;
}
.pilotPara {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 30px;
}

.pilotTextBox {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.pilotText {
  font-size: 18px;
  font-weight: 500;
  flex-basis: 40%;
}
.pilotText:last-child {
  font-weight: 600;
}
.btnContainer {
  margin: 20px 0;
}
.quoteBtn {
  width: 170px;
  background-color: #216fb9;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  border-radius: 50px;
  border: none;
  padding: 10px;
}
/* .specializeBoxs {
  display: flex;
  gap: 30px;
 
  flex-wrap: wrap;

  width: 100%;
} */

/* .specializeBox {
  width: 30%;
  background-color: #292d32;
  color: #fff;
  font-size: 23px;
  font-weight: 600;
  padding: 20px;
  flex-shrink: 0;
  box-sizing: border-box;
} */
.showMobile {
  display: none;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .showDesktop {
    display: none;
  }
  .showMobile {
    display: block;
  }

  .pilotsDetailsContainer {
    padding: 100px 10px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
  }
  .pilotsProfile {
    margin-top: 20px;
    display: flex;
    gap: 30px;
    flex-direction: column;
    position: relative;
  }
  .pilotPic {
    width: auto;
  }
  .pilotTextBox {
    flex-direction: column;
    gap: 30px;
  }

  .pilotName {
    font-size: 20px;
    font-weight: 700;
    margin: 5px 0;
  }
  .pilotLicence {
    font-size: 14px;
    font-weight: 400;
    margin: 5px 0;
  }
  .pilotText {
    font-size: 14px;
  }
  .pilotPic img {
    /* width: 100%; */
  }
  .btnContainer {
    position: absolute;
    bottom: -30px;
    right: 0;
  }
  .pilotDesc {
    width: 100%;
  }
  /* .specializeBoxs{
  
    flex-wrap: nowrap;
    overflow: scroll;
  }
  .specializeBox {
    width: 200px;
    font-size: 16px;
  } */
  .forMobileChange {
    display: flex;
    gap: 20px;
    align-items: center;
  }
  /* .specializes{
    margin-top: 30px;
  } */
}
