.inActiveBreadCrumb{
font-size: 16px;
font-weight: 600;
display: flex;
align-items: center;
}
.bread{
font-size: 16px;
font-weight: 300;
color: #216FB9;
display: flex;
align-items: center;

}
