.container{
    width: 80%;
    margin: 0 auto;
    
}



.top {
    padding: 20px;
    display: flex;
    gap: 50px;
    align-items: center;
  }


  .back {
    width: 20px;
    & img {
      background-color: #216fb9;
      padding: 10px;
      border-radius: 50%;
      width: 100%;
    }
  }



  .backText {
    font-size: 14px;
    font-weight: 500;
    color: #6e7898;
  }


  .desc {
    font-size: 14px;
    font-weight: 500;
    color: #000000;
  
    & span {
      color: #216fb9;
    }
  }

.baterySection{
    display: flex;
    margin: 20px auto;
    justify-content: space-between;
    position: relative;
}

.heading {
  font-size: 22px;
  font-weight: 700;
  color: #216fb9;
  position: relative;
}
.heading::after {
  content: " ";
  position: absolute;
  width: 50px;
  height: 2px;
  background-color: #216fb9;
  bottom: -10px;
  left: 0px;
}
.details {
  padding: 20px 0px;
  width: 80%;
  margin: 0 auto;
}
.Mpower{
  padding: 20px 0px;
  width: 120px;
  & img{
    width: 100%;
  }
}
  @media screen and (max-width: 768px) and (min-width: 320px) {
.container{
  width: 90%;
}

    .backText {
        font-size: 10px;
      }
    .top {
        gap: 30px;
      }
      .back {
        width: 15px;
      }

      .desc {
        font-size: 12px;
      }

      .baterySection{
        flex-direction: column;
    
      }
      .heading {
        font-size: 17.39px;
      }
      .Mpower{
        width: 90px;
        padding: 10px 0px;
      }
  }