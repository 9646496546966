.headCon {
  box-shadow: 0px 7px 29px 0px #d9d9d980;
  position: relative;
}

.hr {
  margin: 0px;
}
.header {
  display: flex;
  margin: 0 auto;
  width: 60%;
  justify-content: space-evenly;
  padding: 20px;
}
.header::after {
  content: " ";
  position: absolute;
  width: 1px;
  height: 80%;
  background-color: #d9d9d9;
  top: 20px;
  display: none;
}
.iconCon {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #2c2c2c;
}
.iconCon:hover {
  color: #216fb9;
}

.icon {
  width: 25px;

  & img {
    width: 100%;
    /* color: #2c2c2c; */
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%);
  }
}
.icon:hover {
  & img {
    -webkit-filter: grayscale(0);
    -moz-filter: grayscale(0);
    -o-filter: grayscale(0);
    -ms-filter: grayscale(0);
    filter: grayscale(0);
  }
}

.descName {
  font-size: 14px;
  position: relative;
}

@media screen and (max-width: 768px) and (min-width: 320px) {
  .iconCon {
    width: 40%;
  }
  .header::after {
    display: block;
  }
  .icon {
    width: 25px;

    & img {
      width: 100%;
      /* color: #2c2c2c; */
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -o-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      filter: grayscale(100%);
    }
  }
  .icon:hover {
    & img {
      -webkit-filter: grayscale(0);
      -moz-filter: grayscale(0);
      -o-filter: grayscale(0);
      -ms-filter: grayscale(0);
      filter: grayscale(0);
    }
  }
  .header {
    flex-wrap: wrap;
    width: 80%;
    gap: 26px;
  }
}
