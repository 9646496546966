@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
  /* font-family: 'Poppins', sans-serif; */
}

.ContactForm {
  text-align: center;
  margin: 30px 60px;
  /* font-family: 'Poppins', sans-serif; */
}

.contactFormCardContainer {
  width: 100%;
  display: flex;
  gap: 40px;
  color: #ffffff;
  box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.1);
  font-family: 'Poppins', sans-serif;
  padding: 10px;
  border-radius: 15px;
  box-sizing: border-box;
  margin: 40px 0;
}

.leftSideContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  width: 40%;
  min-height: 450px;
  background-color: rgba(33, 111, 185, 1);
  box-sizing: border-box;
  padding: 20px;
  border-radius: 15px 15px 15px 15px;
  min-height: 420px;
  position: relative;
  z-index: 2;
}
.cardbottumimg {
  position: absolute;
  bottom: -4px;
  right: 0;
  z-index: -1;
}
.rightSideContainer {
  width: 50%;
  position: relative;
}
.formHeading {
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 22px;
  text-align: initial;
}
.formPara {
  color: #c9c9c9;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 16px;
  text-align: initial;
}
.logoText {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}
.contactText {
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  text-decoration: none;
  cursor: pointer;
}

.socialLogo {
  display: flex;
  gap: 20px;
}

.sectionHeading {
  font-size: 40px;
  font-weight: 700;
  color: #000000;
  margin: 10px 0;
}
.sectionPara {
  display: flex;
  justify-content: center;
}
.sectionPara p {
  width: 60%;
  margin: 20px;
  font-size: 20px;
  font-weight: 400;
  color: #000000;
}
.cardBtn {
  font-size: 16px;
  font-weight: 500;
  border: none;
  background-color: #216fb9 ;
  border-radius: 20px;
  padding: 10px 40px;
  color: #ffffff;
  cursor: pointer;
}

.paperPlane{
  position: absolute;
  bottom: 60px;
  right: 30%;
  transform: translate(50%,50%);
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
  .ContactForm {
    text-align: center;
    margin: 40px 0;
  }

  .contactFormCardContainer {
    flex-direction: column-reverse;
    width: 100%;
    min-width: auto;
    box-shadow: none;
    padding: 0;
  }
  .leftSideContainer {
    width: 100%;
    border-radius: 0;
  }
  .rightSideContainer {
    width: 100%;
    margin-bottom: 100px;
  }
  .paperPlane{
    
    bottom: -60px;
    right: 50%;
    /* left: 10px; */
    transform: translate(50%,50%);
  }
  .sectionHeading {
    font-size: 22px;
  }
  .sectionPara {
    display: flex;
    justify-content: center;
  }
  .sectionPara p {
    width: 100%;
    margin: 10px;
    font-size: 16px;
  }

  .cardbottumimg {
    /* right: -65px; */
  }
  .cardBtn {
    font-size: 12px;
  }
}
