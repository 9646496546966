.container {
  width: 80%;
  margin: 0 auto;
  background-color: #80caff12;
  padding: 30px;
  border-radius: 20px;
  box-sizing: border-box;

}
.icons {
  display: flex;
  justify-content: space-around;
  
}
.head {
  font-size: 32px;
  font-weight: 500;
  color: #000000;
  width: 100%;
  text-align: center;

  & span {
    font-size: 34px;
    font-weight: 800;
    color: #216fb9;
  }
}
.iconCon {
  min-width: 120px;
  background-color: #ffffff;
  text-align: center;
  border-radius: 12px;
  padding: 20px;
  box-sizing: border-box;

  & p {
    font-size: 16px;
    font-weight: 600;
    color: #216fb9;
  }
}
.iconCon:hover {
  box-shadow: 0px 7px 29px 0px #216fb933;
  scale: 1.1;
  transition: 0.2s ease-in-out;
}
@media screen and (max-width: 768px) and (min-width: 320px) {
 
.icons{
    flex-wrap: wrap;
    gap: 15px;
}
.head{
    font-size: 20px;

    & span{
        font-size: 20px
    }
}
.container{
    padding: 20px;
    box-sizing: border-box;
    width: 90%;
}

}