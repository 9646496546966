.bannerSection {
  width: 100%;
  position: relative;
  & img {
    width: 100%;
  }
}

.imgMob {
  display: none;
}

.positionR {
  position: relative;
}
.positionA {
  position: absolute;
}

.serviceName {
  bottom: 5%;
  left: 5%;

  & h1 {
    color: #fff;
    font-size: 50px;
    font-weight: 600;
  }
}

.mainConatainer {
  padding: 40px 80px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.sectionheading {
  font-size: 20px;
  font-weight: 600;
  color: #216fb9;
}

.borderbottom {
  border-bottom: 2px solid #216fb9;
  display: inline-block;
  padding-bottom: 10px;
}

.descriptionBox {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  line-height: 1.4;
}
