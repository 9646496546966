.dronePilottraining {
  text-align: center;
}
.mainContainer {
  position: relative;
}

.mainContainer::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 5;
}

.textContainer {
  position: absolute;
  top: 40%;
  left: 3%;
  color: #fff;
  z-index: 9;
  text-align: left;
}
.imgContainer {
  /* width: 90%; */
  /* margin: 0 auto; */
  /* border-radius: 20px; */

  overflow: hidden;
  & img {
    width: 100%;
  }
}

.heading {
  font-size: 35px;
}
.para {
  font-size: 20px;
}

.listheading {
  font-size: 30px;
  margin-top: 40px;
}

.tlist {
  & li {
    margin: 10px 20px;
    list-style: disc;
    font-size: 18px;
  }
}
.hidedek {
  display: none;
}

.sectionHeading {
  font-size: 40px;
  /* color: #216FB9; */
  font-weight: 700;
  margin: 10px 0 40px;
}
.ctabtn{
  margin-top: 40px;
}
.btnstyles{
  border-radius: 25px;
  background-color: #216FB9;
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
  .hidedek {
    display: block;
  }

  .hideMob {
    display: none;
  }

  .sectionHeading {
    font-size: 22px;
    /* color: #216FB9; */
    font-weight: 700;
    margin: 10px 0 40px;
  }

  .textContainer {
    position: absolute;
    top: 5%;
    left: 3%;
    color: #fff;
    z-index: 9;
    text-align: left;
  }
  .imgContainer {
    /* width: 90%; */
    /* margin: 0 auto; */
    /* border-radius: 20px; */

    overflow: hidden;
    & img {
      width: 100%;
    }
  }

  .heading {
    font-size: 25px;
  }
  .para {
    font-size: 18px;
  }

  .listheading {
    font-size: 20px;
    margin-top: 40px;
  }

  .tlist {
    & li {
      margin: 10px 20px;
      list-style: disc;
      font-size: 18px;
      text-align: left;
    }
  }
}
