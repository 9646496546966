.container {
  width: 80%;
  margin: 70px auto;
}
.head {
  width: 100%;
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  color: #000000;
  padding: 10px 0px;
  box-sizing: border-box;
  & span {
    font-size: 34px;
    font-weight: 800;
    color: #216fb9;
  }
}
.cards {
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  width: 375px !important;
  margin: 50px auto;
  overflow: hidden;
}

.img {
  width: 100%;

  & img {
    width: 100%;
  }
}

.slickstyles {
  width: 100%;
  box-sizing: border-box;
  /* width: 0 auto; */
}
.details {
  padding: 25px;

  & h4 {
    font-size: 24px;
    font-weight: 700;
    color: #216fb9;
    margin: 0px;
    padding: 5px 0px;
  }
}
.logos {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.certified {
  background-color: #216fb9;
  display: flex;
  align-items: center;
  border-radius: 40px;
  padding: 5px;
  gap: 5px;

  & p {
    color: #ffffff;
    font-size: 10px;
    font-weight: 600;
  }
}
.company {
  font-size: 14px;
  font-weight: 400;
  color: #00000080;
}
.desc {
  padding: 30px 0px;
}
.cards:hover {
  box-shadow: 0px 7px 29px 0px #216fb933;
  scale: 1.1;
  transition: 0.2s ease-in-out;
}
.btn {
  width: 100%;
  text-align: center;
visibility: hidden;

  & button {
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    text-transform: inherit;
    border-radius: 199px;
  }
}
.cards:hover .btn{
    visibility: visible;
  
}
@media screen and (max-width: 768px) and (min-width: 320px) {
 .head{
  font-size: 20px;
  & span{
    font-size: 25px;
  }
 }
 .container{
  width: 90%;
  margin: 0 auto;
 }
 .cards{
  width: 295px !important;
 }
}
