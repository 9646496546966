.heading {
  font-size: 22px;
  font-weight: 700;
  color: #216fb9;
  position: relative;
}
.heading::after {
  content: " ";
  position: absolute;
  width: 50px;
  height: 2px;
  background-color: #216fb9;
  bottom: -10px;
  left: 0px;
}
.details {
  padding: 20px 0px;
}
.desc {
  list-style: disc;
  margin: auto;
  padding: revert;

  & li {
    list-style: disc;
    font-size: 16px;
    font-weight: 500;
  }
}
.documents {
  display: flex;
  gap: 20px;
  padding: 10px 0px;
}
.specification {
  font-size: 16px;
  font-weight: 400;
}
.btn {
  font-size: 16px;
  font-weight: 700;
  color: #216fb9;
}

@media screen and (max-width: 768px) and (min-width: 320px) {
  .heading {
    font-size: 17.39px;
  }
  .desc {
    & li {
      font-size: 11.23px;
    }
  }
  .btn {
    font-size: 11.23px;
  }
  .specification {
    font-size: 11.23px;
  }
}
