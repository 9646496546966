.CardContainer{
    padding: 20px;
    border-radius: 25px;
    box-sizing: border-box;
    border: 1px solid #D2D2D2;
    box-shadow: 0px 4px 10px 0px #00000026;

}
.profileDetails{
    display: flex;
    justify-content: space-between;
}

.leftside{
    display: flex;
    gap: 20px;
    align-items: center;
}