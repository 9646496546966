.accordion-summary {
  font-size: 32px;
  font-weight: 700;
  color: #000000;
  transition: color 0.3s;
  text-align: center;
}

.accordion-summary.expanded {
  color: #216fb9;
}
.accordion-Details {
  font-size: 20px;
  font-weight: 400;
  padding: 0px 30px 0px 90px !important;
}
.accordion-Details.active {
  color: #3c3c43d9;
}
.typo {
  font-size: 48px !important;
  font-weight: 700;
  color: #3c3c4380;
  padding-right: 20px;
}
.close {
  background-color: #216fb9;
  border-radius: 25px;
  padding: 5px;
  color: #ffffff;
}
.add {
  background-color: #216fb933;
  color: #216fb9;
  border-radius: 24px;
  padding: 5px;
}
/* sx={{backgroundColor:"#216FB933",color:"#216FB9" ,borderRadius:"24px",padding:"5px"}} */

/* sx={{backgroundColor:"#216FB9",color:"#FFFFFF" ,borderRadius:"24px",padding:"5px"}} */
@media screen and (max-width: 768px) and (min-width: 320px) {
  .accordion-summary {
    font-size: 16px;
  }
  .typo {
    font-size: 28px !important;
    padding-right: 10px;
  }
  .accordion-Details {
    padding: 0px !important;
    font-size: 12px;
  }
  .close{
    padding: 0px;
  }
  .add{padding: 0px;}
}
