.container {
  margin: 50px auto;
  width: 80%;
}

.heading {
  margin-top: 70px;
  width: 100%;
  text-align: center;
  color: #216fb9;
  font-size: 32px;
  font-weight: 700;
  background-color: #80caff12;
  padding: 10px;
}
@media screen and (max-width: 768px) and (min-width: 320px) {
  .heading {
    margin-top: auto;
    font-size: 18px;
    width: 95%;
      }
  .container {
    width: 90%;
    margin: 30 auto;
  }
}
