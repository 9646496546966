.topDetailsBar {
  display: flex;
  /* gap: 30px; */
  justify-content: space-between;
  margin-bottom: 20px;
}

.jobBox {
  background-color: #216fb9;
  color: #fff;
  padding: 25px;
  border-radius: 20px;
  text-align: center;
  box-sizing: border-box;
  width: 30%;
}

.jobBox > p {
  font-size: 18px;
  color: #fff;
  font-weight: 500;
}

.jobBox > h3 {
  font-size: 40px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 0;
  margin-top: 20px;
}

.centerJoblisting {
  display: flex;
  gap: 30px;
}

.jobRequest {
  width: 60%;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  /* border: 1px solid #000; */
}
.upcomingJobs {
  width: 40%;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  /* border: 1px solid #000; */
}

.completedJobTable {
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
  border-radius: 30px;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .topDetailsBar {
    display: block;
  }
  .jobBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    padding: 15px;
  }

  .jobBox > p {
    font-size: 12px;
    font-weight: 500px;
  }
  .jobBox > h3 {
    font-size: 20px;
    font-weight: 700;
    margin-top: 0;
  }
  .centerJoblisting {
    display: block;
  }
  .jobRequest {
    width: 100%;
    margin-bottom: 30px;
  }
  .upcomingJobs {
    width: 100%;
    margin-bottom: 30px;
  }
}
