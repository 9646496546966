.heroContainer {
    height: 83vh;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    background-position: center center;
  }
  
  .deskContainer {
    display: block;
  }
  
  .homePageB {
    position: absolute;
    left: 75%;
    top: 16%;
    transform: translate(-50%, 25%);
    color: white;
    text-align: center;
  }
  
  .animText {
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 40px;
  }
  
  .animText {
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 40px;
    animation-duration: 3s;
    animation-fill-mode: both;
    animation-direction: alternate;
  }
  
  .animClass4 {
    animation-name: fadeInBottom;
  }
  
  .animClass5 {
    animation-name: fadeInBottom;
  }
  
  .animClass6 {
    animation-name: fadeInBottom;
  }
  @keyframes fadeInBottom {
    from {
      opacity: 0;
      transform: translateY(100%);
    }
    to {
      opacity: 1;
    }
  }
  .mobBannerCon {
    display: none;
  }
  .mobFirstBanner {
    display: none;
  }
  
  .tabBannerContainer {
    display: none;
    width: 100%;
    /* height: 80vh; */
  
  }
  
  
  @media only screen and (max-width: 992px) and (min-width: 768px) {
    .deskContainer {
      display: none;
    }
    .mobBannerCon {
      display: none;
    }
    .tabBannerContainer {
      display: block;
      width: 100%;
      /* height: 80vh; */
  
    }
    .tabBannerItem {
      margin: 10px 0px;
      width: 100%;
      height: 400px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
  }
  
  
  
  @media only screen and (max-width: 767px) {
    .deskContainer {
      display: none;
    }
    .tabBannerContainer {
      display: none;
      width: 100%;
      /* height: 80vh; */
  
    }
    .heroContainer {
      display: none;
      height: 100vh;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .mobFirstBanner {
      display: block;
      /* height: 100vh; */
      background-repeat: no-repeat;
      background-size: cover;
    }
    .mobBannerCon {
      display: block;
      width: 100%;
      /* margin: 10px auto; */
    }
    .homePageB {
      position: absolute;
      left: 50%;
      top: 0%;
      width: 100%;
  
      transform: translate(-50%, 25%);
      color: white;
      text-align: center;
    }
  
    .firstImgCon {
      /* height: 234px; */
      height: 85vh;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
  
    .animText {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 10px;
    }
  
    .animText {
      animation-duration: 3s;
      animation-fill-mode: both;
      animation-direction: alternate;
    }
  
    .animClass1 {
      animation-name: fadeInBottom;
      font-size: 16px;
    }
  
    .animClass2 {
      animation-name: fadeInBottom;
      font-size: 16px;
    }
  
    .animClass3 {
      animation-name: fadeInBottom;
      font-size: 16px;
    }
    @keyframes fadeInBottom {
      from {
        opacity: 0;
        transform: translateY(100%);
      }
      to {
        opacity: 1;
      }
    }
  }
  