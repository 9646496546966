.NewsArtical {
  padding: 40px 40px 80px;
  box-sizing: border-box;
  text-align: center;
}

.cardBox {
  width: 80%;
  margin: 20px auto;
  /* border: 1px solid #ccc; */
  border-radius: 25px;
  overflow: hidden;
  box-shadow: 0px 0px 5.518134593963623px 0px #216fb938;
  /* margin: 20px 0; */
}

.imgContainer {
  width: 100%;
  position: relative;

  & img {
    width: 100%;
  }
}

.overviewtext{
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.25);
  color: #ffffff;
  padding: 0 10px;
  font-size: 12px;
}

.cardText {
  padding: 0 20px 20px;
  box-sizing: border-box;
}
.heading {
  font-size: 25px;
  margin: 10px 0;
}

.para {
  font-size: 20px;
  line-height: 1.4;
}
.slickstyles {
  width: 100%;
  box-sizing: border-box;
  /* width: 0 auto; */
}

.sectionHeading {
  font-size: 40px;
  font-weight: 700;
  color: #000000;
  margin: 10px 0;
}
/* slick slider css */
.customarrow {
  background-color: transparent;
  border: none;
  color: #000;
  position: absolute;
  cursor: pointer;
  z-index: 5;
}

.customprevarrow {
  top: 50%;
  left: -35px;
}

.customnextarrow {
  top: 50%;
  right: -35px;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .sectionHeading {
    font-size: 25px;
    font-weight: 700;
    color: #000000;
    margin: 10px 0;
  }

  .NewsArtical {
    padding: 20px 20px 40px;
    box-sizing: border-box;
    text-align: center;
  }

  .cardText {
    padding: 10px;
    box-sizing: border-box;
  }
  .heading {
    font-size: 20px;
  }
  
  .para {
    font-size: 16px;
    line-height: 1.4;
  }
}
