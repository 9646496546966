.ExclusiveEbooksCard {
  background: rgb(33, 111, 185);
  background: linear-gradient(
    90deg,
    rgba(33, 111, 185, 1) 50%,
    rgba(3, 25, 41, 1) 100%
  );
  padding: 40px;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ebookDesc{
    width: 40%;
}

.heading{
    color: #FEFF86;
    font-size: 50px;
    font-weight: 700;
    margin: 0;
}

.subHeading{
    color: #fff;
    font-size: 25px;
    font-weight: 500;
    margin: 0 0 34px;
}

.ebookDesc > p{
    color: #F8F8F8;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .ExclusiveEbooksCard{
    display: block;
    padding: 10px;
    border-radius: 8px;
  }
  .ebookDesc{
    width: 100%;
  }
  .heading{
    font-size: 26px;
  }
  .subHeading{
    font-size: 12px;
  }
  .ebookDesc > p{
    font-size: 12px;
  }
  .bookImg{
    width: 300px;
    margin-top: 30px;
  }
  .bookImg img{
    width: 100%;
  }
}