


.Con{
    margin: 90px auto;
}

.videoCon{
    width: 100%;

    & video{
        width: 100%;
    }
}

@media screen and (max-width: 768px) and (min-width: 320px) {
 .Con{


    margin: 20px auto;
 }
}