.cardContianer {
  position: relative;
  width: 430px;
  /* padding: 10px; */
  box-sizing: border-box;
  /* background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%); */
  min-height: 390px;


  & img {
    width: 100%;
    border-radius: 10px;
    box-shadow: 2px 4px 4px rgba(0,0,0 , 0.5) ;
  }
}

.detailsContainer {
  position: absolute;
  bottom: 0;
  width: 95%;
  left: 0;
  right: 0;

  margin: 10px auto 20px;
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
}

.priceContainer {
  background: linear-gradient(
    90deg,
    #201d57 26.5%,
    rgba(115, 115, 115, 0) 100%
  );
  padding: 10px;
  display: flex;
  justify-content: space-between;
  /* gap: 10px; */

  & p {
    width: 120px;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: #fff;

    & span {
      font-size: 20px;
      font-weight: 700;
    }
  }


  & .bookNow{
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 14px;
      font-weight: 700;
  }

}
.servicePRating {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
  & h5
   {
    margin: 5px 0;
    background: linear-gradient(90deg, #9538CE 0%, #4B1C68 100%);
    color: #fff;
    border-radius: 25px;
    width: 100px;
    text-align: center;
    padding: 5px;

  }

  & p{
    background: #398415;
    color: #fff;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 5px;
    align-items: center;
    display: flex;
    gap: 5px;
  }

  /* & p {
        margin: 5px 0;
    } */
}

.serviceTitle,
.serviceDecs {
  margin: 5px 0;
}

.serviceTitle{
    font-size: 20px;
    font-weight: 500;
    color: #000;
}

.serviceDecs{
    font-size: 12px;
    font-weight: 400;
    color: #585757CC;
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .cardContianer {
    position: relative;
    width: 350px;
    /* padding: 10px; */
    box-sizing: border-box;
    /* background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%); */
    min-height: 380px;
  
  
    & img {
      width: 100%;
      border-radius: 10px;
      box-shadow: 2px 4px 4px rgba(0,0,0 , 0.5) ;
    }
  }
}