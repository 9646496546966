.ProfileContainer {
  display: flex;
  gap: 40px;
}
.imageContainer {
  width: 25%;
  border-radius: 100%;

  overflow: hidden;
  position: relative;
}
.imageContainer > img {
  width: 100%;
  overflow: hidden;
}
.cameraEdit {
  position: absolute;
  background-color: #fff;
  z-index: 4;
  bottom: 15px;
  right: 45px;
  border-radius: 100%;
  border: none;
  width: 50px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 12px;
  cursor: pointer;
}
.cameraEdit > img {
  width: 100%;
}

.detailContainer {
  width: 70%;
}
.topLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pilotName {
  color: #216fb9;
  font-size: 30px;
  font-weight: 700;
}
.editBtn {
  background-color: #216fb9;
  color: #fff;
  font-size: 18px;
  border: none;
  border-radius: 50px;
  padding: 5px 20px;
  height: fit-content;
  display: flex;
  align-items: center;
  gap: 10px;
}
.details {
  display: flex;
  flex-wrap: wrap;
  /* gap: 40px; */
  row-gap: 30px;
  justify-content: space-between;
}
.pilotDetail {
  color: #292d32;
  font-size: 20px;
  font-weight: 500;
  width: 50%;
}
.mobileshow {
  display: none;
}
.titleheading {
  font-size: 18px;
  font-weight: 600;
  color: #292d32;
}
.pilotlice {
  display: none;
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
  .ProfileContainer {
    display: block;
    /* gap: 40px; */
  }
  .mobileHide {
    display: none;
  }
  .mobileshow {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .imageContainer {
    width: 65%;
    margin: 20px auto;
  }
  .cameraEdit {
    bottom: 3px;
  }
  .detailContainer {
    width: 100%;
  }
  .topLine {
    display: block;
    text-align: center;
  }
  .details {
    display: block;
    margin-top: 20px;
  }
  .pilotDetail {
    width: 100%;
    box-shadow: 0px 0px 4px 0 rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 20px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
  }
  .pilotDetail:nth-of-type(3) {
    display: none;
  }
  .pilotName {
    margin-bottom: 0;
  }
  .pilotlice {
    display: block;
  }
}
