@media only screen and (max-width: 767px) and (min-width: 320px) {


}


@media only screen and (max-width: 767px) and (min-width: 320px) {
  .mobileRes{
  padding: 0px;
  margin : 0px;
  width: 100%
  }

  .css-4mi5a9{
    margin: 0px;
  }

  

}