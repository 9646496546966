.BannerConatiner {
  position: relative;

  & .desktopImg {
    width: 100%;
  }
}
.BannerConatiner::after{
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.2) 21.6%,
    rgba(0, 0, 0, 0.8) 100%
  );
  z-index: 3;
}
.leftsideDetails {
  width: 50%;
}
.textContainer {
  position: absolute;
  bottom: 5%;
  left: 5%;
  color: #fff;
  z-index: 5;
}

.desc{
  width: 50%;
  margin-bottom: 20px;
}

.overallDetails {
  width: 1400px;
  margin: 40px auto;
  display: flex;
  justify-content: space-between;
}
.listofTopics {
  list-style: inherit !important;
  /* list-style-image: url("../../assets/CouresDetile/detailsTikMark.png") !important; */

  & li {
    list-style: inherit;
    margin-left: 30px;
  }
  & li::marker{
    content: url("../../assets/CouresDetile/detailsTikMark.png");
   font-size: small;
  }
}

.innerBox {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 300px;

  & h4,
  h5 {
    margin: 5px auto;
  }
}

.coursedetailsAccordion {
  margin: 20px 0;
}
.rightsidePriceBox{
    width: 45%;
}

.courseFeebox {
  background-color: #80caff12;
  box-shadow: 0px 2px 10px 0px #00000026;
  border-radius: 25px;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
}

.offerStatus {
  & span {
    color: #fff;
    background-color: #ff4b4b;
    border-radius: 8px;
    width: 140px;
    padding: 5px 10px;
    box-sizing: border-box;
  }
}
.courseTitle {
  color: #999999;
  font-size: 22px;
  font-weight: 600;
}

.pricingcontainer {
  display: flex;
  gap: 20px;
  align-items: center;

  & h4 {
    text-decoration: line-through;
    color: #989898;
    font-size: 20px;
    font-weight: 400;
  }
  & h2 {
    font-size: 55px;
    font-weight: 700;
    margin: 0;
  }
}

.extracut {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.courDetails{
  display: flex;
  flex-wrap: wrap;
}

.btnContainer {
  margin-top: 30px;

  & .enrollBtn {
    width: 100%;
    background: linear-gradient(180deg, #216fb9 0%, #0f3253 100%) !important;
  }
}

/* form container */

.fromContainer {
  background-color: #fff;
  padding: 40px;
  & .sectionHeading {
    margin: 0;
  }
}
.textfieldContainer {
  padding: 2px 40px;

  & .fullwidth {
    margin: 10px 0;
    width: 100%;
  }
}
.innerbox {
  width: 100%;
}
.fromsubmitbtn {
  background: linear-gradient(180deg, #216fb9 0%, #0f3253 100%) !important;
  color: #ffffff !important;
  box-shadow: 0 4px 14px 0 rgba(23, 127, 141, 0.25) !important;
  padding: 10px 30px !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  margin-top: 40px !important;
  width: 100% !important;
}

.pricePopup{
  display: none;
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
  .BannerConatiner{
  
    
  }
  .rightsidePriceBox {
    display: none;
  }

  .overallDetails {
    width: 100%;
    margin: 40px auto;
    display: flex;
    flex-direction: column;
    position: relative;
    /* margin-bottom: 100px; */
  }

  .leftsideDetails{
    width: 90%;
    margin: 20px auto;
  }

  .pricePopup{
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: -2px -2px 16px 0px #E95E331A;
    position: sticky;
    bottom: 10px;
    background-color: #fff;
    padding: 10px;
    box-sizing: border-box;
  }
  .priceleftnew{
    & h2{
      margin: 5px 0;
    }
  }
  .fromContainer {
    background-color: #fff;
    padding: 10px;
    box-sizing: border-box;
    & .sectionHeading {
      margin: 0;
    }
  }

  .textfieldContainer{
    padding: 2px 0;
  }
  .boxForMobile{
    width: 80% !important;
    padding: 20px !important;
  }
}
