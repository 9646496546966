.JobRequestContainer {
  
  }
  .heading {
    color: #216fb9;
    font-size: 20px;
    font-weight: 500;
  }
  
  .jobBox {
    background-color: #f6f6f6;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 15px;
    /* display: flex; */
    /* justify-content: space-between; */
  }

  .jobName {
    font-size: 16px;
    font-weight: 500;
    color: #000;
  }
  .jobDays {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: 400;
  }

  @media only screen and (max-width: 767px) and (min-width: 320px) {
    .jobName{
      font-size: 14px;
    }

    .jobDays{
      font-size: 12px;
    }
  }