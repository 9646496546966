
.Maincontainer{
    position: sticky;
    top: 0;
    width: 45%;
    display: flex;
    flex-direction: column;
   gap: 50px;
}

.container {
    /*   */
    border-radius: 18px;
    border: 1px solid #90909066;
    width: 90%;
    /* margin: 20px auto; */
    padding: 40px;
    /* justify-content: space-evenly; */
    display: flex;
    flex-direction: column;
    /* position: sticky; */
    /* top: 0; */
    align-self: flex-start;
    height: max-content;
    gap: 20px;
  }
  .name {
    font-size: 18px;
    font-weight: 500;
    color: #000000;
  }
  .amount {
    font-size: 12px;
    font-weight: 500;
    color: #000000;
    & span {
      font-size: 18px;
      font-weight: 600;
      color: #216fb9;
    }
  }
  .price {
    display: flex;
    justify-content: space-between;
  }
  .hr {
    border: none;
    border-bottom: 1px solid #d9d9d9b2;
    width: 100%;
    margin: 0px;
  }
  
  .subname {
    font-size: 14px;
    font-weight: 500;
  }
  .inputs {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px;
    & input {
      width: 45%;
      border: 1px solid #d9d9d966;
      border-radius: 4px;
      padding: 10px;
      background-color: transparent;
    }
    & button {
      width: 45%;
      border: 1px solid #d9d9d966;
      text-transform: inherit;
    }
  }
  .pincode {
    display: flex;
    gap: 10px;
  }
  .numbers {
    border: 0.5px solid #6e789880;
    border-radius: 5px;
    overflow: hidden;
    min-width: 100px;
    display: flex;
    justify-content: space-between;
    & button {
      border: none;
      padding: 5px 13px;
      color: #ffffff;
      background-color: #216fb9;
      font-weight: bold;
    }
  }
  .quantity {
    display: flex;
    justify-content: space-between;
  }
  .order {
    font-size: 10px;
    font-weight: 400;
    color: #6e7898;
    padding: 5px 0px;
  }
  .links {
    font-size: 10px;
    font-weight: 400;
    color: #216fb9;
  }
  .shipping {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .hours {
    font-size: 12px;
    font-weight: 400;
    color: #6e7898;
  }
  .buy {
    width: 70%;
    align-self: center;
    text-transform: inherit;
    background: linear-gradient(180deg, #216fb9 0%, #0f3253 100%);
    padding: 10px;
  }
  .formBottom {
    width: 100%;
    text-align: center;
  }
  .subTotal{
font-size: 19.15px;
font-weight: 600;
display: flex;
justify-content: space-between;
  }
  @media screen and (max-width: 768px) and (min-width: 320px) {
    .container {
      width: 90%;
      padding: 20px;
      margin: 20px 0px;
     gap: 20px;
    }
  
    .name {
      font-size: 14px;
    }
    .amount {
      font-size: 12px;
  
      & span {
        font-size: 12px;
      }
    }
    .subTotal{
        font-size: 14px;
    }
  }
  