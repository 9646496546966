.column1 {
    display: flex;
flex-direction: column;
flex-shrink: 0;
}
.column1 h1  {
    color: #FFF;
font-size: 42px;
font-family: Montserrat;
font-weight: 700;
text-align: center;
}
p {
    margin:0;
    line-height: 1.4;
}
.content  {
    color: #FFF;
text-align: justify;
font-size: 16px;
font-family: Montserrat;
font-weight: 500;
box-sizing: border-box;
padding-inline: 30px;
}

.column1_flex {
    display: flex;
    gap:10px;
    /* justify-content: space-between; */
    box-sizing: border-box;
    padding-inline: 30px;
    margin-block:0.83rem;
}

.col_2 p {
    color: #FFF;
font-size: 16px;
font-family: Poppins;
text-align: left;
}
ul {
    margin: 0;
    padding: 0;
}
ul li { 
    list-style: none;
    text-align: justify;
}
ul li a { 
    color: #FFF;
font-size: 16px;
font-family: Montserrat;
font-weight: 500;
text-decoration: none !important;
}
.Link_head {
    text-align: justify;
    /* padding-inline-start: 40px; */
}
.footer_links_list > li {
    padding-bottom: 20px;
}
.copyright {
    color: #FFF;
font-size: 16px;
font-family: Fira Sans;
font-weight: 500;
text-align: center;
}

.footer_linkMob{
    display: none;
}
.socialMediaLinks{
    text-align: center;
}

.socialMediaLinks > img{
    margin: 0 10px;
}

.contactText{
    color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
    .column4{
        display: none;
    }

    .footer_links{
        display: none;
    }
    .footer_linkMob{
        display: block;
    }
}