.header {
  display: flex;
  margin: 0 auto;
  width: 60%;
  justify-content: space-evenly;
  padding: 20px;
}
.headCon {
  box-shadow: 0px 7px 29px 0px #d9d9d980;
}

.iconCon {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #2c2c2c;

}
.iconCon:hover{
  color: #216FB9;
}
.icon {
  width: 25px;

  & img {
    width: 100%;
    /* color: #2c2c2c; */
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
      -o-filter: grayscale(100%);
     -ms-filter: grayscale(100%);
         filter: grayscale(100%); 
  }
}
.icon:hover{
   & img{
    -webkit-filter: grayscale(0);
    -moz-filter: grayscale(0);
      -o-filter: grayscale(0);
     -ms-filter: grayscale(0);
         filter: grayscale(0); 
   }
}
.descName {
  font-size: 14px;
  font-weight: 400;
  /* color: #2c2c2c; */
}

.hr {
  margin: 0px;
}
.container {
  width: 80%;
  margin: auto;
}

.back {
  width: 20px;
  & img {
    background-color: #216fb9;
    padding: 10px;
    border-radius: 50%;
    width: 100%;
  }
}
.top {
  padding: 20px;
  display: flex;
  gap: 50px;
  align-items: center;
}
.backText {
  font-size: 14px;
  font-weight: 500;
  color: #6e7898;
}
.desc {
  font-size: 14px;
  font-weight: 500;
  color: #000000;

  & span {
    color: #216fb9;
  }
}
.bottom {
  display: flex;
  gap: 40px;
  padding: 30px 0px;
  box-sizing: border-box;
}
.right {
  display: flex;
  align-items: center;
  background-color: #216fb9;
  color: #ffffff;
  height: 20px;
  margin: auto 0;
  border-radius: 40px;
  padding: 5px 10px;
  gap: 5px;

  & p {
    font-size: 10px;
    font-weight: 600;
  }
}
.sliderDesc {
  display: flex;
  width: 80%;
  margin: 0 auto;
  justify-content: space-between;
  padding: 30px 0px;
}

.company {
  font-size: 28px;
  font-weight: 600;
  color: #216fb9;

  & span {
    font-size: 24px;
    font-weight: 600;
    color: #6e7898;
  }
}

.sliderRight {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-right: 30px;
  & span {
    font-size: 24px;
    font-weight: 600;
    color: #000000;
  }
}
.buy {
  border-radius: 199px;
  font-size: 14px;
  font-weight: 600;
  background-color: #216FB9;
  border: none;
  color: white;
  padding: 5px 20px;
}
@media screen and (max-width: 768px) and (min-width: 320px) {
  .backText {
    font-size: 10px;
  }
  .desc {
    font-size: 12px;
  }
  .bottom {
    gap: 20px;
  }
  .left {
    width: 80px;

    & img {
      width: 100%;
    }
  }
  .top {
    gap: 30px;
  }
  .back {
    width: 15px;
  }
  .iconCon {
    width: 40%;
  }
  .header {
    flex-wrap: wrap;
    width: 90%;
    gap: 20px;
  }
  .company {
    font-size: 16px;
    & span {
      font-size: 14px;
    }
  }
  .sliderRight {
    flex-direction: column;
    gap: 0px;
    & span {
      font-size: 12px;
    }
  }
  .buy {
    font-size: 10px;
  }
  .container{
    width: 90%;
  }
}
