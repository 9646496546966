.relative {
  position: relative;
}

.absolute {
  position: absolute;
}
.centerAlign {
  text-align: center;
  margin: 0 auto;
}

.sectionHeading {
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  margin-top: 80px;
  & span {
    color: #216fb9;
  }
}

.outLinedBtn {
  border-radius: 30px !important;
  border: 1px solid #216fb9 !important;
  color: #216fb9 !important;
  background-color: #fff !important;
  font-size: 16px !important;
  padding: 10px 30px !important;
}

/* Our services */
.serviceCardlisting {
  width: 1400px;
  margin: 0 auto;
  /* overflow: auto;
  display: flex;
  flex-direction: row; */
  gap: 40px;
}

.card {
  width: 370px;
  padding: 10px;
  box-sizing: border-box;
}

.cardTitle {
  font-size: 36px;
  font-weight: 700;
  margin: 10px 0;
}

.cardText {
  font-size: 18px;
  font-weight: 400;
  margin: 10px 0;
  line-height: 1.4;
}

.cardImg {
  width: 350px;

  & img {
    width: 100%;
  }
}

.OSbgone {
  top: -10%;
  left: 0;
  z-index: -1;
}

.OSbgtwo {
  bottom: -10%;
  left: 0;
  z-index: -1;
}

.OSbgthree {
  top: -17%;
  right: 0;
  z-index: -1;
}

/* drone pilot training  */

.dtpVideodesk {
  width: 100%;
}
.dtpVideomobile {
  width: 100%;
  display: none;
}

.videoContainer::after {
  content: " ";
  width: 100%;
  height: 99.5%;
  position: absolute;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 21.6%,
    rgba(0, 0, 0, 0.4) 100%
  );
  top: 0;
  left: 0;
  display: inline-block;
  /* z-index: 5; */
}

.onvideoText {
  position: absolute;
  width: 80%;
  margin: 0 auto;
  bottom: 5%;
  z-index: 20;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.lefttext {
  color: #fff;
  line-height: 1.4;

  & h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 0 auto;
  }

  & p {
    font-size: 16px;
    font-weight: 400;
    margin: 0 auto;
  }

  & h5 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
  }

  & ul {
    & li {
      font-size: 16px;
      font-weight: 500;
      list-style: disc;
      margin-left: 20px;
    }
  }
}
/* D A A S */
.daasInnerContainer {
  overflow: auto;
}

.daasListing {
  width: 1400px;
  margin: 80px auto 0;
  display: flex;
  /* gap: 40px; */
  justify-content: space-between;
}

/* free background img */

.serviceLeftback {
  top: -60%;
  z-index: -1;
}

/* Our Drone */
.DroneInnerConatiner {
  display: flex;
  justify-content: space-around;
}

/* Excellent Center */
.eCContainer {
  background: linear-gradient(
    180deg,
    rgba(228, 244, 255, 0.02) 0%,
    #e4f4ff 100%
  );
  margin: 40px auto;
}

.sectionPara {
  display: flex;
  justify-content: center;
}
.sectionPara > p {
  width: 60%;
  /* margin: 20px; */
  margin-bottom: 40px;
  font-size: 20px;
  font-weight: 400;
  color: #000000;
}

.ecListingContainer {
  width: 1400px;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ecContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.SuccessStories {
  width: 100%;
  padding: 40px 40px 80px;
  box-sizing: border-box;
  text-align: center;
}

.NewsArtical {
  padding: 40px 40px 80px;
  box-sizing: border-box;
  text-align: center;
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
  .serviceCardlisting {
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
    flex-direction: column;
  }

  .card {
    width: 100%;
  }

  .cardImg {
    width: 100%;
  }

  /* DTP video container */
  .videoContainer::after {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 21.6%,
      rgba(0, 0, 0, 0.6) 100%
    );
  }
  .onvideoText {
    flex-direction: column;
    /* justify-content: ; */
    gap: 10px;
    align-items: center;
  }
  .lefttext {
    text-align: center;

    & h4 {
      font-size: 18px;
      font-weight: 700;
      margin: 0 auto;
    }

    & p {
      font-size: 12px;
      font-weight: 400;
      margin: 0 auto;
    }

    & h5 {
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 0;
      text-align: left;
    }

    & ul {
      /* text-align: center; */
      & li {
        /* text-align: center; */
        font-size: 12px;
        font-weight: 500;
        list-style: disc;
        margin-left: 20px;
      }
    }
  }

  .dtpVideodesk {
    width: 100%;
    display: none;
  }

  .dtpVideomobile {
    width: 100%;
    display: block;
  }
  .daasListing {
    width: 1000px;
    /* overflow: scroll; */
    padding: 20px;
    box-sizing: border-box;
    margin: 50px auto 0;
    display: flex;

    gap: 20px;
    /* justify-content: space-between; */
  }

  /* free background img */

  .serviceLeftback {
    width: 200px;
    top: -60%;
    z-index: -1;

    & img {
      width: 100%;
    }
  }

  .DroneInnerConatiner {
    padding: 20px;
    box-sizing: border-box;
  }
  .sectionPara {
    display: flex;
    justify-content: center;
  }
  .sectionPara > p {
    width: 100%;
    margin: 10px auto 20px;
    font-size: 14px;
  }

  /* Our Drone */
  .DroneInnerConatiner {
    display: block;
  }

  /* exellent Center */
  .ecListingContainer {
    display: block;
    width: 100%;
  }

  .eCContainer {
    padding: 20px;
    box-sizing: border-box;
  }
}
