.ServiceListingContainer{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 1400px;
    margin: 40px auto;
    gap: 30px;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
    .ServiceListingContainer{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        margin: 40px auto;
        gap: 30px;
        padding: 10px;
        box-sizing: border-box;
    }
}