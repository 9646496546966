.orderBox {
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 20px;
  align-items: center;
}
.leftside {
  display: flex;
  gap: 20px;
  align-items: center;
  width: 35%;
}
.center {
  width: 10%;
  text-align: center;
}
.droneImg {
  width: 40%;
}
.droneImg > img {
  width: 100%;
}
.incdecBtn {
  display: flex;
  background-color: #216fb9;
  height: 30px;
  align-items: center;
  width: 100px;
  box-sizing: border-box;
  padding: 5px;
  justify-content: space-between;
  border-radius: 50px;
  color: #fff;
}
.incdecBtn > h5 {
  font-size: 18px;
  font-weight: 400;
}
.PMBtn {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 22px;
}
.nameAmount {
  display: flex;
  flex-direction: column;
  width: 50%;
  /* justify-content: space-around;
  align-self: stretch; */
}

.dName {
  font-size: 20px;
  font-weight: 600;
  color: #000;
}

.dAmount {
  font-size: 18px;
  font-weight: 600;
  color: #000;
}

.rightSide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 25%;
  align-items: flex-end;
}
.dDate {
  white-space: nowrap;
  font-size: 16px;
  font-weight: 600;
  color: #14ae5c;
}
.cancelBtn {
  font-size: 16px;
  font-weight: 600;
  color: #f24b4b;
  background-color: transparent;
  border: 1px solid #f24b4b;
  border-radius: 50px;
  padding: 10px 20px;
  margin-top: 40px;
  width: 60%;
  justify-content: flex-end;
}

.mobileOn {
  display: none;
}
.qtyShow {
  background-color: #216fb9;
  color: #fff;
  /* text-align: center; */
  box-sizing: border-box;
  border-radius: 50px;
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
  padding: 5px 10px;
  
}
.couresdetails{
  /* display: flex;
  gap: 5px; */
}
.orderBatch{
  white-space: nowrap;
  font-size: 16px;
}
.couresdetails > span{
  font-size: 16px;
  position: relative;

}
 .couresdetails > span:not(:last-child){
 
  margin-right: 10px;
 }

 .couresdetails > span:not(:last-child)::after{
  content: "|";
  position: absolute;
  top: -1px;
  right: -6px;
 }

 .onlineBatch {
  color: #fc8a00;
  background-color: rgba(252, 138, 0, 0.12);
  border: 1px solid #fc8a00;
  border-radius: 50px;
  width: 60%;
  padding: 5px 0;
  margin: 10px 0 0;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}

.offlineBatch {
  color: #216fb9;
  background-color: #e5f9ff;
  border: 1px solid #216fb9;
  border-radius: 50px;
  width: 60%;
  padding: 5px 0;
  margin: 10px 0 0;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
  .orderBox {
    padding: 10px;
    border-radius: 7px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    box-shadow: none;
  }
  .leftside {
    width: 65%;
    gap: 5px;
    justify-content: space-between;
  }
  .droneImg{
    width: 30%;
  }
  .dName {
    font-size: 12px;
  }
  .dAmount {
    font-size: 12px;
  }
  .center {
    display: none;
  }
  .rightSide {
    width: 45%;
    height: 120px;
  }
  .nameAmount {
    justify-content: space-between;
    width: 65%;
  }
  .dDate {
    font-size: 10px;
  }
  .cancelBtn {
    width: 80%;
    padding: 5px 10px;
    font-size: 12px;
  }
  .PMBtn {
    font-size: 18px;
  }
  .mobileOn {
    display: flex;
    /* justify-content: space-between; */
    gap: 10px;
    margin-top: 10px;
    align-items: center;
  }
  .qtyHeading {
    text-align: center;
    font-size: 14px;
  }
  .qtyShow {
    width: 35px;
    margin-top: 0;
    text-align: center;
    font-size: 10px;
  }
  .mobileOn > .incdecBtn {
    height: 22px;
    width: 70px;
  }
  .incdecBtn > h5 {
    font-size: 12px;
    font-weight: 400;
  }
  .orderBatch{
    font-size: 12px;
  }
  .couresdetails > span{
    font-size: 12px;
  }
}
