.mainContainer {
  /* padding-top: 100px; */
  margin: 20px 60px;
}

.innerContainer {
  padding: 10px;
  display: flex;
  gap: 20px;
  background-color: rgba(217, 217, 217, 0.3);
  border-radius: 20px;
}

.leftSearchContainer {
  width: 30%;
  background-color: #216fb9;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 100px;
}

.headerBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}
.headerBtn h3 {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
}

.headerBtn div {
  display: flex;
  gap: 20px;
  align-items: center;
}
.fieldHeader {
  color: #fff;
  font-weight: 400;
  font-size: 14px;
}

.locationMark {
  padding: 6px;
  box-sizing: border-box;
}
.locationMarkActive {
  border: 1px solid #fff;
  border-radius: 50px;
  /* font-size: 10px; */
  padding: 5px;
  box-sizing: border-box;
}
.rightMapCantainer {
  width: 70%;
}

.checkboxContainer {
  display: inline-block;
}
.kilometerIndicator {
  display: flex;
  gap: 20px;
}
.kilometerIndicator div span {
  display: inline-block;
  margin-right: 10px;
}
.indicatorColors {
  width: 10px;
  height: 10px;
  border-radius: 50px;
}
.indicatorText {
  color: #fff;
  font-size: 14px;
  font-size: 400;
}
.btnContainer {
  display: flex;
  gap: 50px;
  justify-content: space-between;
}
.cancelBtn {
  color: #fff;
  font-size: 14px;
  font-size: 400;
  padding: 10px 0;
  border: 1px solid #ffffff;
  border-radius: 50px;
  background-color: #216fb9;
  width: 45%;
}
.serachBtn {
  background-color: #ffffff;
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  border-radius: 50px;
  border: 1px solid #ffffff;
  width: 45%;
}
.fullScreenMap {
  border: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.mapFloatBtn {
  display: none;
}

.mobileSerach {
  display: none;
}
.deckstopHide {
  display: none;
}

.mainPilotsCardContainer {
  background-color: #ffffff;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: scroll;
  height: 790px;
}
.mainPilotsCardContainer p {
  margin-bottom: 20px;
}
.noOfPilotsFound {
  font-size: 20px;
  font-weight: 700;
  color: #070707;
}
.pilotsCard {
  border-radius: 10px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.3);
  /* display: flex; */
  padding: 10px;
  box-sizing: border-box;
  gap: 10px;
  margin-bottom: 15px;
}
.pilotsDetails h3 {
  /* margin-top: 5px; */
  font-size: 20px;
  font-weight: 500;
  color: #216fb9;
}
.pilotsDetails {
  display: flex;
}
.pilotsImg {
  width: 80px;
}

.pilotsImg img {
  width: 100%;
}
.ratingAndDistance {
  display: flex;
  align-items: center;
  gap: 20px;
}
.ratingAndDistance span {
  display: inline-block;
}
.ratingsToShow {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #80CAFF;
}
.distanceAway {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #80CAFF;
}
.pricingDecs {
  display: flex;
  flex-direction: column;
  align-items: flex-end;  
}
.pricingDetails {
  display: flex;
  gap: 20px;
  margin: 10px 0;
  width: 88%;
}
.pricingDetails > span {
  color: #fff;
  background-color: #216fb9;
  border-radius: 50px;
  padding: 4px 15px;
  white-space: nowrap;
}
span{
    /* white-space: nowrap; */
}
.pilotsDesc {
  margin: 20px 0;
  width: 88%;
}
.pilotsCardBtn {
  display: flex;
  justify-content: flex-end;
  gap: 40px;
  margin-top: 30px;
}
.viewBtn{
  width: 115px;
  padding: 10px 0;
  border: 1px solid #000000;
  border-radius: 50px;
  background-color: #fff;
  font-size: 12px;
  font-weight: 400;
}
.secdQuoteBtn{
  width: 115px;
  padding: 10px 0;
  /* border: 1px solid #000000; */
  border-radius: 50px;
  background-color: #000000;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
  .mainContainer {
    /* padding-top: 60px; */
  }
  .leftSearchContainer {
    display: none;
  }

  .rightMapCantainer {
    width: 100%;
    position: relative;
  }
  .mainContainer {
    margin: 10px;
  }
  .innerContainer {
    padding: 0;
    gap: 0;
    background-color: #fff;
  }
  .fullScreenMap {
    border: 0;
    width: 100%;
    height: 460px;
    border-radius: 10px;
  }
  .mapFloatBtn {
    display: block;
    position: absolute;
    bottom: 70px;
    left: 0;
    right: 0;
    text-align: center;
  }
  .serachBtnMobile {
    background-color: #216fb9;
    color: #ffffff;
    width: 180px;
    height: 40px;
    border: 0;
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
  }
  .drawerContainer {
    background-color: #216fb9;
    padding: 20px;
    box-sizing: border-box;
    /* border-radius: 10px 10px 0 0; */
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .headerBtn {
    display: block;
  }
  .mobileSerach {
    padding-bottom: 20px;
    display: block;
  }
  .mobileHeaderBtn {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .mobileTabBtn {
    display: flex;
    gap: 10px;
  }
  .locationMarkActive {
    border: 1px solid #216fb9;
    color: #216fb9;
    border-radius: 50px;
    width: 35px;
    height: 35px;
    /* font-size: 10px; */
    padding: 5px;
    box-sizing: border-box;
  }
  .deckstopHide {
    display: block;
  }
  .mainPilotsCardContainer {
    padding: 0;
    height: 460px;
  }
  .pilotsCard {
    width: 330px;
    margin: auto;
    margin-bottom: 12px;
  }
  .pricingDetails {
    width: 100%;
    overflow: scroll;
  }
  /* .pricingDetails::-webkit-scrollbar {
    display: none;
  } */
}
