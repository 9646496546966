.specializeBoxs {
  display: flex;
  gap: 30px;
  /* margin-right: 20px; */
  flex-wrap: wrap;
  /* white-space: nowrap; */
  /* overflow-x: scroll !important; */
  width: 100%;
}

.specializeBox {
  width: 30%;
  background-color: #292d32;
  color: #fff;
  font-size: 23px;
  font-weight: 600;
  padding: 20px;
  flex-shrink: 0;
  box-sizing: border-box;
  border-radius: 30px;
  text-align: center;
}
/* .pilotName{
  font-size: 20px;
  font-weight: 600;
} */

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .specializeBoxs {
    /* gap: 15px; */
    justify-content: space-between;
    /* width: 350px; */
    flex-wrap: wrap;
    /* overflow: scroll; */
  }
  .specializeBox {
    width: 170px;
    font-size: 16px;
    border-radius: 10px;
  }
  .specializes {
    margin-top: 30px;
  }
}
