.JobRequestContainer {
  /* padding: 15px;
  box-sizing: border-box;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  border: 1px solid #000; */
}
.heading {
  color: #216fb9;
  font-size: 20px;
  font-weight: 500;
}

.jobBox {
  background-color: #f6f6f6;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
}
.leftSide {
  width: 60%;
}
.rightSide {
  width: 37%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.jobName {
  font-size: 16px;
  font-weight: 500;
  color: #000;
}
.jobDays {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 400;
}
.jobtiming {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 400;
}
.userRequierment {
  border: 1px solid #000;
  border-radius: 50px;
  padding: 0 5px;
  font-size: 10px;
  font-weight: 400;
}

.btnGroup {
  display: flex;
  gap: 20px;
}

.declineBtn {
  width: 100%;
  padding: 10px 20px;
  color: #000;
  background-color: transparent;
  border: 1px solid #000;
  border-radius: 50px;
  
}

.acceptBtn {
  width: 100%;
  color: #fff;
  background-color: #216fb9;
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
}

.boxHeading > .jobName:nth-of-type(2){
  display: none;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .jobBox{
    display: block;
  }
  .leftSide{
    width: 100%;
  }
  .rightSide{
    margin: 10px auto 0;
    width: 100%;
  }
  .rightSide > .jobName{
    display: none;
  }
  .boxHeading{
    display: flex;
    /* justify-content: space-between; */
    gap: 20px;
  }

  .boxHeading > .jobName:nth-of-type(2){
    display: block;
  }

  .jobName{
    font-size: 14px;
    /* font-weight: 500; */
  }
  .btnGroup{
    margin: 0 auto;
  }
  .declineBtn, .acceptBtn{
    padding: 10px;
    width: 50%;
    font-size: 12px;
  }
  .jobDays , .jobtiming{
    font-size: 12px;
  }
}