.topLine{
    display: flex;
    align-items: center;
    gap: 20px;
    width: 90%;
    margin: 0 auto;
}

.detailsContainer{
    width: 1400px;
    background-color: rgba(217, 217, 217, 0.1);
    margin: 0 auto 30px;
    padding: 50px;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    border-radius: 20px;
}
.box{
    width: 30%;
}
.headings{
    font-size: 21px;
    font-weight: 500;
    color: #000;
}
.details{
    font-size: 21px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.70);
}

.btnGroup{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin-bottom: 40px;
}

.decline{
    font-size: 25px;
    background-color: #F6F6F6;
    color: #216FB9;
    border: 1px solid #216FB9;
    border-radius: 22px ;
    width: 10%;
    padding: 5px 0;
}

.accept{
    font-size: 25px;
    background-color: #216FB9;
    color: #fff;
    border-radius: 22px ;
    border: none;
    width: 10%;
    padding: 5px 0;
}