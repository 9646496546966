.container {
  width: 45%;
  padding: 20px 0px;
}
.heading {
  font-size: 22px;
  font-weight: 700;
  color: #216fb9;
  position: relative;
}
.heading::after {
    content: " ";
    position: absolute;
    width: 50px;
    height: 2px;
    background-color: #216FB9;
    bottom: -10px;
    left: 0px;
}
.details {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
}
.name {
  font-size: 16px;
  font-weight: 400;
  color: #6e7898;
}
.desc {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  padding: 0px 10px;
}
.hr {
  border: 1px solid #d9d9d9;
}

@media screen and (max-width: 768px) and (min-width: 320px) {
  .container {
    width: 100%;
  }
  .name{
    font-size: 12.65px;
  }
  .desc{
    font-size: 12.65px;
  }
  .heading{
    font-size: 17.39px;
  }
}
