.mobileShareIcon{

    width: 30px;
    height: 30px;
    background: transparent;
    /* box-shadow: 2px 2px 5px rgba(0, 170, 226, 0.15); */
    border-radius: 5px;
    /* padding: 5px 10px; */
}
.sharebtn{
  padding: 0;
  background-color: transparent;
  border: none;
}

  .shareImg{
    width: 100%;
    /* height: 35px; */
  }
  .shareLink{
    text-decoration: none;
    color: #000;
  }
  