.mainQuoteContainer {
  padding: 0px 50px 100px;
}
.topHeading{
  font-size: 50px;
  font-weight: 700;
  color: #000;
}
.radiosBtns {
  display: flex;
  flex-wrap: wrap;
  /* flex-basis: 30%; */
  /* column-gap: 40px; */
  /* row-gap: 20px; */
  flex-direction: row;
}

.radioBtn {
  width: 22%;
}
.otherFiled{
  width: 30%;
  margin: 20px 0;
}

.selectDates{
  display: flex;
  gap: 40px;
  margin: 20px 0;
}
.address{
width: 80%;
}
.formFilled{
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
.defaultFillValue{
  width: 35%;
}

.formBtns{
  margin: 50px 50px 0;
  display: flex;
  gap: 40px;
}

.cancel{
  width: 150px;
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 50px;
  padding: 15px 0;
  font-size: 16px;
  font-weight: 500;
}
.submit{
  width: 200px;
  color: #fff;
  background-color: #000;
  border: 1px solid #000;
  border-radius: 50px;
  padding: 15px 0;
  font-size: 16px;
  font-weight: 500;
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
  .mainQuoteContainer {
    padding: 0px 15px 100px;
  }
  .topHeading{
    font-size: 20px;
    text-align: center;
  }
  .radiosBtns {
    justify-content: space-between;
  }
  .radioBtn {
    width: 48%;
    /* justify-content: space-between; */
  }
  .otherFiled{
    width: 100%;
    
  }
  .address{
    width: 100%;
    }
}
