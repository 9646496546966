.container {
  width: 80%;
  margin:30px auto;
}

.heading {
  font-size: 26px;
  font-weight: 600;
  color: #216fb9;
  background-color: #80caff12;
  border-radius: 10px;
  width: 100%;
  text-align: center;
  padding: 10px 0px;
  box-sizing: border-box;
}
.desc {
  font-size: 18px;
  font-weight: 500;
  color: #6e7898;
  padding: 20px 0px;
  box-sizing: border-box;
  text-align: center;
}
.cardCon{
    display: flex;
    flex-wrap: wrap;
    gap: 100px;
    justify-content: center;
    margin: 50px auto;
}
.mobile{
    display: none;
}
@media screen and (max-width: 768px) and (min-width: 320px) {
 .heading{
    font-size: 18px;
 }
 .desc{
    font-size: 14px;
 }
 .cardCon{
    display: none;
 }
 .mobile{
    display: block;
 }
 .container{
    width: 90%;
 }
}