.carouselContainer {
    position: relative;
    width: 100%;
    /* max-width: 800px;  */
    margin: 0 auto;
  }
   
  .mySwiper {
    width: 100%;

    & 
    .carousel.carousel-slider .control-arrow{
      color: #000 !important;
    }
    
  }
   
  .slideGroup {
    display: flex;
    justify-content: space-between;
  }
  
  