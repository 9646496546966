.container {
  border: 0.3px solid #90909066;
  border-radius: 5px;
  width: 70%;
  margin: 0 auto;
  padding: 10px 0px;
  box-sizing: border-box;
}
.head {
  width: 100%;
  text-align: center;
  font-size: 17px;
  font-weight: 400;
  color: #216fb9;
}
.couponCon {
  display: flex;
  align-items: center;
  border: 0.3px solid #000000;
  border-radius: 5px;
  width: 90%;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
  justify-content: space-between;
}
.name {
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
}
.details {
  display: flex;
  gap: 10px;
  align-items: center;

  & span {
    font-size: 12px;
    font-weight: 400;
    color: #878787;
  }
}
.show {
  text-align: center;
  width: 100%;
  & button {
    text-transform: inherit;
    font-size: 14px;
    font-weight: 400;
    color: #216fb9;
  }
}
.apply {
  text-transform: inherit;
  font-size: 14px;
  font-weight: 400;
  color: #b5182c;
}
.coupon{
    display: flex;
    flex-direction: column;
    gap: 20px;
}