.numbers {
  border: 0.5px solid #6e789880;
  border-radius: 5px;
  overflow: hidden;
  min-width: 70px;
  max-width: 80px;
  display: flex;
  margin: auto;
  justify-content: space-between;
  & button {
    border: none;
    padding: 5px 11px;
    color: #ffffff;
    background-color: #216fb9;
    font-weight: bold;
  }
}
.container {
  width: 45%;
}
.about {
  display: flex;
  align-items: center;
  gap: 20px;
}
.table {
  width: 100%;

  & th {
    font-size: 16px;
    font-weight: 500;
    padding: 15px 0px;
  }
}
.desc {
  font-size: 16px;
  font-weight: 500;
}
.price {
  text-align: center;
}
.off {
  font-size: 16px;
  font-weight: 400;
  color: #989898;
  text-decoration: line-through;
}
.abouttd {
  width: 60%;
  padding: 20px 0px;
}
.data {
  row-gap: 20px;
}
.tableHeading{
    background-color: #80CAFF12;
   
}
.tbody{
    position: relative;
}
.data::after{
    content: ' ';
    position:absolute;
    background-color: #00000033;
    width: 100%;
    height: 1px;
    left: 0px;

}
.data:first-child::after{
    display: none;
}