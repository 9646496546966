.con {
  width: 80%;
  margin:50px auto;
  & h3 {
    font-size: 32px;
    font-weight: 700;
    color: #216fb9;
    background-color: #80caff12;
    padding: 10px;
    width: 100%;
    text-align: center;
  }
}
