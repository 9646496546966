.ListingPage{
    display: flex;
    justify-content: space-between;
    padding: 30px;
    box-sizing: border-box;
}

.filtersection{
    width: 20%;
    background-color: #F6F6F6;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    height: fit-content;
}
.filterdiv{
    background-color: #0000000A;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
    border-radius: 10px;

    & h3{
        margin: 5px 0;
    }
}
.listingSection{
    width: 75%;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
    .ListingPage{
        display: block;
        padding: 10px;
    }
    
    .filtersection{
       display: none;
        
    }

    .listingSection{
        width: 100%;
    }
}