.cardConatainer{
    width: 350px;
    position: relative;
    border-radius: 10px;


    & img{
        width: 100%;
    }
}

.cardConatainer::after{
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 38.54%, #000000 69.79%);
    border-radius: 10px;
}

.textContainer{
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto 20px;
    width: 90%;
    z-index: 20;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
    .cardConatainer{
        width: 100%;
        
    }
    
}