.container {
  width: 95%;
  & img {
    width: 100%;
  }
}
@media screen and (max-width: 768px) and (min-width: 320px) {
  .container {
    width: 100%;
  }
}
