.listingBox {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 20px;
}
.listingBox > h4 {
  color: #292d32;
  font-size: 18px;
  font-weight: 600;
  margin-top: 0;
}
.listingBox > p {
  color: #8d8d8d;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .listingBox {
    padding: 0px;
    box-shadow: none;
  }
}