.container {
  background-color: #80caff12;
  padding: 50px 0;
}
.cardCon {
  display: flex;

  margin: 50px auto;
  justify-content: space-around;
}
.card {
  width: 345px;
  border-radius: 12px;
  background-color: #ffffff;
  padding: 10px;
  box-sizing: border-box;
}
.img {
  width: 100%;

  & img {
    width: 100%;
  }
}
.desc {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  padding: 10px;
  box-sizing: border-box;
}
.bottom {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  box-sizing: border-box;
}
.price {
  font-size: 20px;
  font-weight: 700;
  color: #216fb9;

  & span {
    font-size: 16px;
    font-weight: 500;
    color: #00000080;
    padding-left: 5px;
  }
}
.card:hover {
  box-shadow: 0px 7px 29px 0px #216fb933;
  scale: 1.1;
  transition: 0.2s ease-in-out;
}
.btn {
  border-radius: 100px !important;
  text-decoration: inherit !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}
@media screen and (max-width: 768px) and (min-width: 320px) {
  .cardCon {
    width: 90%;
    margin: 30px auto;
  }
}
