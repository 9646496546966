.SuccessStories {
  width: 100%;
  padding: 40px 40px 80px;
  box-sizing: border-box;
  text-align: center;
}
.videoCard {
  width: 90% !important;
  margin: 0 auto;
  border-radius: 25px;
  overflow: hidden;
}
.videolisting{
    margin-top: 40px;
}

.slickstyles {
  width: 100%;
  box-sizing: border-box;
  /* width: 0 auto; */
}

.sectionHeading {
  font-size: 40px;
  font-weight: 700;
  color: #000000;
  margin: 10px 0;
}

/* slick slider css */
.customarrow {
  background-color: transparent;
  border: none;
  color: #000;
  position: absolute;
  cursor: pointer;
  z-index: 5;
}

.customprevarrow {
  top: 50%;
  left: -35px;
}

.customnextarrow {
  top: 50%;
  right: -35px;
}


@media only screen and (max-width: 767px) and (min-width: 320px) {
    .sectionHeading {
        font-size: 25px;
        font-weight: 700;
        color: #000000;
        margin: 10px 0;
      }
      .SuccessStories {
        width: 100%;
        padding: 20px 20px 40px;
        box-sizing: border-box;
        text-align: center;
      }
}