.MainContianer {
  /* padding-top: 100px; remove this when router steup is done */
  /* padding-bottom: 100px; */
  height: 100vh;
  display: flex;
  background-image: url(../assets/images/loginpagebg.png);
  background-repeat: no-repeat;
  /* width: 100rem; */
}

.leftSideContainer {
  /* background: rgb(33, 111, 185);
  background: linear-gradient(
    130deg,
    rgba(33, 111, 185, 1) 50%,
    rgba(255, 255, 255, 1) 50%
  );
  height: 100vh; */
  /* background-image: url(../assets/images/loginpagebg.png);
  background-repeat: no-repeat; */
  width: 65%;
  box-sizing: border-box;
  padding: 40px;
  position: relative;
}
.mainPageHeading {
  position: absolute;
  top: 30%;
  font-size: 40px;
  font-weight: 700;
  color: #fff;
  line-height: 1.4;
}
.topLogoHeadingMobiles{
    display: none;
}
.rightSideContainer{
    position: relative;
    width: 40%;
}
.autheticationBox{
    position: absolute;
    top: 20%;
    width: 70%;
}
.forgetPass{
    margin: 10px 0;
    text-align: right;
}

.forgetpassLink{
    text-decoration: none;
    color: #000;
    font-size: 16px;
    font-weight: 500;
}
.registreationbox{
    text-align: left;
}
.chooseHeading{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
}
.submitBtn{
    color: #fff;
    background-color: #216FB9;
    border: none;
    border-radius: 50px;
    padding: 18px 35px;
    font-size: 16px;
    margin-top: 30px;
}

.registerBtn{
    color: #fff;
    background-color: #216FB9;
    border: none;
    border-radius: 50px;
    padding: 18px 35px;
    font-size: 16px;
    margin-top: 50px;
}
.loginForm{
    width: 100%;
}

/*  login otp css */
.otpContainer{
    display: flex;
    justify-content: space-between;
    /* gap: 10px; */
}

.otpContainer > div > input{
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    border: 1px solid grey;
    border-radius: 5px;
    padding: 10px;
    outline: none;
    text-align: center;
}
.invalidepart{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.errorShow{
    color: red;
}
.resendOtp{
    background-color: transparent;
    color: #5F5F5F;
    font-size:14px;
    font-weight: 600;
    border: none;
    margin-left: auto;
}




@media only screen and (max-width: 767px) and (min-width: 320px) {
    .MainContianer {
        background-image: none;
    }
    .leftSideContainer {
        display: none;
    }
    .rightSideContainer{
        position: static;
        width: 100%;
    }
    .topLogoHeadingMobiles{
        display: block;
    }
    .autheticationBox{
        position: static;
        top: 20%;
        width: 100%;
    }
}