.formContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 40px;
}
.textfield{
    width: 45%;
}

.fullWidth{
    width: 100%;
}

.btnGroup{
    display: flex;
    gap: 40px;
}

.saveBtn{
    color: #fff;
    background-color: #216FB9;
    font-size: 18px;
    border-radius: 50px;
    border: none;
    padding: 10px;
    width: 100px;
}

.cancleBtn{
    color: #000;
    background-color: #fff;
    font-size: 18px;
    border-radius: 50px;
    border: 1px solid #000;
    padding: 10px;
    width: 100px;
}