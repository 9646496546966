.topLine{
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* gap: 20px; */
    width: 90%;
    margin: 0 auto;
}
.lefSide{
    display: flex;
    align-items: center;
    gap: 20px;
}
.detailsContainer{
    width: 1400px;
    background-color: rgba(217, 217, 217, 0.1);
    margin: 0 auto 30px;
    padding: 50px;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    border-radius: 20px;
}
.box{
    width: 30%;
}
.headings{
    font-size: 21px;
    font-weight: 500;
    color: #000;
}
.details{
    font-size: 21px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.70);
}

.btnGroup{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin-bottom: 40px;
}
.paymentTag{
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    /* width: 100%; */
    padding: 10px 15px;
    border-radius: 20px;
}
.pending {
    background-color: #FFA629;
  }
  
  .received {
    background-color: #4ECB71;
  }
  
  .overdue {
    background-color: #E9665B;
  }