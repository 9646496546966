.locationDropdownList{
    display: flex;
    align-items: center;
    /* justify-content: space-evenly; */
    gap: 10px;
    /* padding: 10px 0; */
    /* position: absolute; */
}

.suggestionActive .listLocationMark{
    color: red;
}
.autocompletedropdowncontainer{
    /* position: absolute; */
    z-index: 99;
    width: 100%;
}