.mainAccountOverview{
  padding: 0 40px;
  /* padding-top: 120px; */
  display: flex;
  gap: 40px;
  box-sizing: border-box;
}
.leftsideTabContainer{
  width: 25%;
}
.pageHeading{
  color: #000;
  font-size: 30px;
  font-weight: 700;
}
.tabListing{
  background-color: #F4F4F4;
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;
}
.tabName{
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 15px;
  cursor: pointer;
}

.tabIcon{
  color: #6F6F6F;
}
/* .tabName:last-child{ */
  /* margin-bottom: 0; */
/* } */
.tabActive{
  color: #216FB9;
}
.tabActive > .tabIcon{
  color: #216FB9;
}

.rightsideContainer{
  width: 75%;
  margin-bottom: 40px;
}


@media only screen and (max-width: 767px) and (min-width: 320px) {
  .mainAccountOverview{
    flex-direction: column;
    padding: 0 10px;
    /* padding-top: 100px; */
  }
  .leftsideTabContainer{
    width: 100%;
  }
  .rightsideContainer{
    width: 100%;
  }
  .tabListing{
    display: flex;
    background-color: #fff;
    overflow: scroll;
    gap: 20px;
    padding: 0;
    border-radius: 0;
  }

  .tabName{
    background-color: #E1E1E1;
    white-space: nowrap;
    padding: 10px;
    border-radius: 10px;
    font-size: 16px;
    gap: 10px;
  }
}