.topDetailsBar {
  display: flex;
  /* gap: 30px; */
  justify-content: space-between;
  margin-bottom: 20px;
}
.profileCard {
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: #216fb9;
  color: #fff;
  box-sizing: border-box;
  padding: 25px;
  border-radius: 20px;
  width: 35%;
}
.pname {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  margin: 20px 0 0;
}
.email {
  display: flex;
  gap: 10px;
  margin: 15px 0;
}
.email > span {
  width: 15px;
}
.email > span > img {
  width: 100%;
}
.email > p {
  font-size: 12px;
  color: #fff;
  font-weight: 400;
}
.mmaBtn {
  background-color: transparent;
  border: none;
  color: #fff;
  font-weight: 700;
  font-size: 12px;
}
.jobBox {
  background-color: #216fb9;
  color: #fff;
  padding: 25px;
  border-radius: 20px;
  text-align: center;
  box-sizing: border-box;
  width: 28%;
}

.jobBox > p {
  font-size: 18px;
  color: #fff;
  font-weight: 500;
}

.jobBox > h3 {
  font-size: 40px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 0;
  margin-top: 20px;
}
.listingBox {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 20px;
}
.listingBox > h4 {
  color: #292d32;
  font-size: 18px;
  font-weight: 600;
  margin-top: 0;
}
.listingBox > p {
  color: #8d8d8d;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .topDetailsBar {
    flex-direction: column;
    gap: 30px;
  }

  .profileCard {
    width: 100%;
    padding: 15px;
  }
  .jobBox{
    width: 100%;
    padding: 15px;
  }
  .profilePic{
    width: 20%;
  }
  .profilePic img{
    width: 100%;
  }
  .profileText{
    width: 70%;
  }
}