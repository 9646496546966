.slickstyles {
  width: 100%;
  width: 0 auto;
}

.mainCardListing {
  width: 100%;
}
.DSCard {
  width: 90%;
  /* position: relative; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px auto;
  /* border: 1px solid #ccc; */
  box-shadow: 0px 0px 4px 0px #00000040;

  min-height: 900px;
  border-radius: 25px;
}
.imgContainer {
  width: 100%;
  margin: 0 auto;
  position: relative;

  & img {
    width: 100%;
  }
}

.DScardText {
  padding: 10px 20px;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  margin: 0 auto;
}

.DCHeading {
  font-size: 30px;
  font-weight: 500;
  margin: 10px 0
}
.DSPara {
  font-size: 20px;
}
/* .slick-slide{
    margin: 0 auto !important;
} */
.DSBtn {
  /* position: absolute; */
  bottom: 1%;
  left: 0;
  width: 100%;
  text-align: right;
  color: #000;
  font-weight: 500;
  z-index: 4;
  padding: 10px 30px;
  box-sizing: border-box;

  & .leranBtn {
    background-color: #216FB9;
    color: #fff;
    font-weight: 500;
    border: none;
    border-radius: 25px;
    padding: 5px 20px;
    font-size: 16px;
  }
}

.overviewtext{
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.25);
  color: #ffffff;
  padding: 0 10px;
  font-size: 12px;
}

/* slick slider css */
.customarrow {
  background-color: transparent;
  border: none;
  color: #000;
  position: absolute;
  cursor: pointer;
  z-index: 5;
}

.customprevarrow {
  top: 50%;
  left: -35px;
}

.customnextarrow {
  top: 50%;
  right: -35px;
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
  .DSCard {
    width: 100%;
    min-height: 650px;
    margin: 5px 0;
    & img {
      width: 100%;
      margin: 0 auto;
    }
  }

  .DCHeading {
    font-size: 18px;
    font-weight: 500;
    margin: 10px 0;
  }
  .DSPara {
    font-size: 14px;
  }

  .DScardText {
    bottom: 7%;
  }

  .leranBtn {
    font-size: 12px;
  }
}
