.onlineBatch {
    color: #fc8a00;
    background-color: rgba(252, 138, 0, 0.12);
    border: 1px solid #fc8a00;
    border-radius: 50px;
    width: 60%;
    padding: 5px 0;
    margin: 10px 0 0;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
  }
  
  .offlineBatch {
    color: #216fb9;
    background-color: #e5f9ff;
    border: 1px solid #216fb9;
    border-radius: 50px;
    width: 60%;
    padding: 5px 0;
    margin: 10px 0 0;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
  }

  @media only screen and (max-width: 767px) and (min-width: 320px) {
    .onlineBatch {
        font-size: 12px;
    }
    .offlineBatch {
        font-size: 12px;
    }
  }