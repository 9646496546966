.LecturesCardContainer {
  /* display: flex; */
  /* gap: 40px; */
  /* justify-content: space-between; */
}

.card {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 11px;
  width: 95%;
  /* max-height: 545px; */
  margin: 10px auto;
}
.card .lecImg img {
  width: 100%;
}
.infoBox {
  padding: 0 20px 20px;
  box-sizing: border-box;
}
.lecturesTitle {
  font-size: 20px;
  font-weight: 500;
  color: #000;
}

.techarName {
  font-size: 13px;
  font-weight: 500;
  color: #686868;
}

.innerBox {
  display: flex;
  flex-wrap: wrap;
  /* gap: 40px; */
  align-items: center;
  justify-content: space-between;
}
.infoIcontext {
  display: flex;
  align-items: center;
  /* justify-content: ; */
  gap: 20px;
  width: 45%;
  font-size: 14px;
  color: rgba(88, 87, 87, 0.8);
  font-weight: 400;
}
.infoIcontext > span {
  color: #292d32;
}
.btnGroup {
  display: flex;
  justify-content: space-between;
}

.droneLikeShare {
  display: flex;
  gap: 10px;
}
.likeBtn {
  background-color: transparent;
  border: none;
  padding: 0;
}
.register {
  border: none;
  border-radius: 34px;
  color: #f3f3f3;
  font-weight: 600;
  background-color: #216fb9;
  font-size: 14px;
  width: 30%;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
    .infoBox{
        padding: 0 10px 10px;
    }
    .lecturesTitle{
        font-size: 15px;
        text-align: left;
    }
    .infoIcontext{
        font-size: 12px;
        gap: 10px;
    }
    .register{
        width: 45%;
        font-size: 12px;
    }
}