.topSection {
  display: flex;
  padding: 20px;
  /* justify-content: space-between; */
  gap: 40px;
}
.likeBtn {
  border: none;
  background-color: transparent;
}
.droneLikeShare {
  display: flex;
}
.buttonContainer {
  display: flex;
  justify-content: space-around;
}
.imgContainer{
  width: 100%;
}

.leftsideContainer{
  width: 45%;
}

.droneName {
  font-size: 30px;
  font-weight: 600;
}
.colorHeading {
  font-size: 20px;
  font-weight: 600;
  color: #292d32;
}
.classicbtn {
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-weight: 400;
  color: #000;
}

.droneColorBar {
  margin: 10px 0;
  display: flex;
  gap: 3px;
}

.droneColor {
  width: 25px;
  height: 25px;
  border: 1px solid #ccc;
  border-radius: 50px;
  /* background-color: red; */
}
.cardBtn {
  font-size: 16px;
  font-weight: 500;
  border: none;
  background-color: #216fb9;
  border-radius: 25px;
  padding: 5px 40px;
  color: #ffffff;
}

.sectionheading {
  padding: 0 40px;
  font-size: 30px;
  font-weight: 600;

  & span{
    color: #686868;
  }
}
.imgbox {
  position: relative;
}
.imgbox::after {
  content: "";
  width: 100%;
  position: absolute;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 30%,
    rgba(0, 0, 0, 0.6) 100%
  );
  top: 0;
  left: 0;
  height: 100%;
}
.imgbox > img {
  width: 100%;
}
.imgbox > h5 {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: #ffffff;
  font-size: 33px;
  font-weight: 500;
  z-index: 2;
}
.aboutSection{
  margin: 10px 50px;

  & ul{
    & li{
      list-style-type: disc;
    }
  }
}
.multiImgcontainer {
  display: flex;
  flex-wrap: wrap;
  padding: 50px 70px;
  /* gap: 50px; */
  justify-content: space-between;
  /* text-align: center; */
  margin: 0 auto;
}
.accessoriesHeading{
  font-size: 30px;
  font-weight: 500;
}
.accessoriesName {
  padding: 50px;
}
.accessName{
  font-size: 20px;
  font-weight: 500;
}
.imgaccessListing {
  display: flex;
  flex-wrap: wrap;
  gap: 70px;
}
.accessoriesimgBox {
  width: 250px;
  height: 200px;
  background-color: #efefef;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 20px;
}

.textSectionHeading {
  font-size: 30px;
  font-weight: 700;
  color: #131313;
}

.textSection {
  margin: 0 40px;
}
.reviewSections {
  display: flex;
}
.reviewCount {
  width: 30%;
}
.reviewFheading {
  font-size: 24px;
  font-weight: 700;
}
.mainCount {
  display: flex;
  gap: 30px;
  align-items: center;
  & h3 {
    font-size: 65px;
    color: #216fb9;
  }
}
.totalcount {
  font-size: 30px;
  font-weight: 400;
}
.reviewbyfeature {
  display: flex;
  gap: 20px;
  margin: 10px 0;
  & p {
    width: 30%;
    font-weight: 500;
    font-size: 18px;
  }
}
.reviewListing {
  width: 60%;
}
.reviewProfile {
  display: flex;
  gap: 20px;
  align-items: center;
}

.mobileShow {
  display: none;
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
  .topSection {
    display: block;
  }
  .leftsideContainer{
    width: 100%;
  }
  .droneName {
    font-size: 20px;
  }
  .colorHeading {
    font-size: 15px;
  }
  .droneLikeShare {
    display: flex;
    justify-content: space-between;
  }
  .sectionheading{
    font-size: 18px;
    padding: 0 20px;
  }
  .imgbox > h5 {
    bottom: 0px;
    left: 20px;
    right: 0;
    transform: translate(0%, 0%);
    font-size: 16px;
  }
  .imgContainer {
    width: 100%;

    & img {
      width: 100%;
    }
  }
  .mobileShow {
    display: block;
  }
  .showinpc {
    display: none;
  }
  .multiImgcontainer {
    padding: 20px 20px;
  }
  .imgBoxes {
    & img {
      width: 100%;
    }
  }
  .accessoriesHeading{
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }
  .accessoriesName {
    padding: 20px;
  }
  .accessName{
    font-size: 12px
  }
  .imgaccessListing {
    gap: 20px;
  }
  .textSectionHeading{
    font-size: 18px;
    text-align: center;
  }
  .textSection{
    margin: 0 20px;
    & p{
      text-align: center;
      margin-bottom: 20px;
    }
  }
  .accessoriesimgBox {
    width: 150px;
    height: 125px;
    & img {
      width: 100%;
      height: 100%;
    }
  }
  .reviewSections{
    display: block;
  }
  .reviewCount{
    width: 100%;
    text-align: center;
  }
  .mainCount{
    justify-content: center;

    & h3{
      font-size: 30px;
    }
  }
  .totalcount{
    font-size: 20px;
  }
  .reviewFheading{
    font-size: 16px;
  }

  .reviewbyfeature{
    justify-content: center;
    & p{
      width: 40%;
      font-size: 14px;
      text-align: left;
    }

    & span {
      font-size: 16px;
    }
  }
  .rbfReating{
    width: 5% !important;
  }
  .reviewListing{
    width: 100%;

    & p{
      font-size: 16px;
      color: rgba(0, 0, 0, 0.5);
      text-align: justify;
    }
    & .reviwdates{
      /* color: #292D32; */
      font-size: 14px;
      font-weight: 400;
    }
  }

  .reviewProfile{

    & h4{
      font-size: 16;
    }
  }
  .mobileShow{

    & .cardBtn{
      width: 100%;
      padding: 10px;
      margin-bottom: 20px;
    }
  }
}
