.cookieCard {
    width: 25%;
    /* height: 200px; */
    background: rgba(33, 111, 185, 1);
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    border-radius: 10px;
    /* position: relative;
    overflow: hidden; */
    text-align: center;
  }
  
  /* .cookieCard::before {
    width: 150px;
    height: 150px;
    content: "";
    background: linear-gradient(to right,rgb(142, 110, 255),rgb(208, 195, 255));
    position: absolute;
    z-index: 1;
    border-radius: 50%;
    right: -25%;
    top: -25%;
  } */
  
  .cookieHeading {
    font-size: 1.5em;
    font-weight: 600;
    color: rgb(241, 241, 241);
    z-index: 2;
  }
  
  .cookieDescription {
    font-size: 0.9em;
    color: rgb(241, 241, 241);
    z-index: 2;
  }
  
  .cookieDescription a {
    color: rgb(241, 241, 241);
  }
  
  .acceptButton {
    padding: 11px 20px;
    background-color: rgba(33, 111, 185, 1);
    transition-duration: .2s;
    border: none;
    color: rgb(241, 241, 241);
    cursor: pointer;
    font-weight: 600;
    z-index: 2;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 20px;
  }
  
  .acceptButton:hover {
    background-color:  rgba(33, 111, 185, 1);
    transition-duration: .2s;
  }
  .d_flex {
    display: flex ;
    gap:20px;
    justify-content: center;
  }

  @media only screen and (max-width: 767px) and (min-width: 320px) {
    .cookieCard {
      width: 100%;
      
    }
    .d_flex {
      display: flex ;
      gap:2rem;
      justify-content: center;
    }
  }
