.SuccessStories {
  width: 100%;
  padding: 40px 40px 80px;
  box-sizing: border-box;
  text-align: center;
}
.videoCard {
  width: 90% !important;
  margin: 0 auto;
  border-radius: 25px;
  overflow: hidden;
}
.videolisting {
  margin-top: 40px;
}

.slickstyles {
  width: 100%;
  box-sizing: border-box;
  /* width: 0 auto; */
}

.sectionHeading {
  font-size: 40px;
  font-weight: 700;
  color: #000000;
  margin: 10px 0;
}

/* slick slider css */
.customarrow {
  background-color: transparent;
  border: none;
  color: #000;
  position: absolute;
  cursor: pointer;
  z-index: 5;
}

.customprevarrow {
  top: 50%;
  left: -35px;
}

.customnextarrow {
  top: 50%;
  right: -35px;
}

.card {
  width: 370px;
  padding: 10px;
  box-sizing: border-box;
  min-height: 745px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
}

.cardTitle {
  font-size: 36px;
  font-weight: 700;
  margin: 10px 0;
}

.cardText {
  font-size: 18px;
  font-weight: 400;
  margin: 10px 0;
  line-height: 1.4;
}

.cardImg {
  width: 350px;

  & img {
    width: 100%;
  }
}

.OSbgone {
  top: -10%;
  left: 0;
  z-index: -1;
}

.OSbgtwo {
  bottom: -10%;
  left: 0;
  z-index: -1;
}

.OSbgthree {
  top: -17%;
  right: 0;
  z-index: -1;
}

.outLinedBtn {
  border-radius: 30px !important;
  border: 1px solid #216fb9 !important;
  color: #216fb9 !important;
  background-color: #fff !important;
  font-size: 16px !important;
  padding: 10px 30px !important;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .sectionHeading {
    font-size: 25px;
    font-weight: 700;
    color: #000000;
    margin: 10px 0;
  }
  .SuccessStories {
    width: 100%;
    padding: 20px 20px 40px;
    box-sizing: border-box;
    text-align: center;
  }
  .card {
    width: 100%;
  }

  .cardImg{
    width: 100%;

    & img{
      width: 100%;
    }
  }
}
