.aboutUs{
    display: flex;
    margin: 40px  80px;
    justify-content: space-between;
    /* align-items: center; */

}

.leftContainer{
    width: 35%;
}

.sectionHeading{
    font-size: 55px;
    color: #216FB9;
    font-weight: 700;
    margin: 10px 0 40px;
}

.para{
    font-size: 20px;
    margin-bottom: 40px;
    line-height: 1.4;
}

.rightContainer{
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: space-between;
    row-gap: 20px;
}

.card{
    width: 40%;
    text-align: center;

    & h4{
        text-transform: capitalize;
        color: #216FB9;
        font-size: 20px;
        font-weight: 700;
    }
}

.imgContainer{
    width: 100%;
    text-align: center;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
    .aboutUs{
        display: flex;
        margin: 10px  20px;
        flex-direction: column;
    
    }

    .leftContainer{
        width: 100%;
    }
    
    .sectionHeading{
        font-size: 25px;
        color: #216FB9;
        font-weight: 700;
        margin: 10px 0 40px;
    }

    .para{
        font-size: 18px;
        margin-bottom: 20px;
        line-height: 1.4;
    }

    .rightContainer{
        width: 100%;
    }

    .card{
        width: 150px;
        text-align: center;
    
        & h4{
            text-transform: capitalize;
            color: #216FB9;
            font-size: 16px;
            font-weight: 700;
        }
    }
}