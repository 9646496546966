.card {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.65);
  padding: 1px;
  border-radius: 20px;
  width: 95%;
  margin: 10px auto;
}

.imgContainer {
  position: relative;
}
.imgContainer > img {
  width: 100%;
}
.droneLikeShare {
  position: absolute;
  display: flex;
  top: 5px;
  right: 5px;
}
.likeBtn {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.cardInfoBox {
  padding: 0 15px 15px;
}
.cardTitle{
    font-size: 22px;
    font-weight: 500;
    color: #606060;
}
.iconContainer {
  display: flex;
  /* justify-content: space-between; */
  gap: 30px;
}
.iconBox {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 17px;
  color: #606060;
  font-weight: 500;
}
.trainingInfo {
  margin-top: 10px;
  font-size: 15px;
  color: #000;
  font-weight: 400;
  text-align: left;
}
