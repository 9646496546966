.bannerContainer {
  position: relative;
}

.bannerContainer::after{
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.50);
  z-index: 5;
}
.bannerVideo{
  width: 100%;
}
.bannerBackground {
  /* background-image: url(../../assets/images/bannerImage.png); */
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 980px;
}
.bannerText {
  width: 80%;
  position: absolute;
  bottom: 10%;
  left: 5%;
  color: #ffffff;
  z-index: 9;
}

.bannerWelcome {
  font-size: 50px;
  font-weight: 700;
  margin: 0;
}

.bannerHeading {
  font-size: 105px;
  font-weight: 700;
  margin: 0;
}

.bannerPara {
  font-size: 30px;
  font-weight: 700;
}

.downArrow {
  display: none;
}
.ourServises{
   margin: 40px 40px 100px;
   text-align: center;
}
.cardSection {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  gap: 30px;
  /* margin: 130px 40px; */
  margin-top: 40px;
}
.card {
  /* text-align: center; */
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  box-sizing: border-box;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* margin: 10px; */
  width: 22%;
  /* min-height: 350px; */
  
}

.cardTitle {
  font-size: 30px;
  font-weight: 700;
  color: #000000;
}

.cardImg{
  width: 20%;
  margin: 0 auto;

  & img {
    width: 100%;
  }
}
.cardText {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.4;
  text-align: justify;
  margin: 10px 0;
  padding: 0 10px;
}

.buttonCon{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.knowmorebtn{
  align-items: center;
  display: flex;
  /* justify-content: flex-end; */
  /* margin-bottom: 20px; */
}
.knowmoreforcard{
  background-color: transparent;
  color: #000000;
  border: none;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  white-space: nowrap;
}
.cardBtn {
  font-size: 16px;
  font-weight: 500;
  border: none;
  background-color: #216fb9;
  border-radius: 25px;
  padding: 15px 40px;
  color: #ffffff;
}
.speakWith {
  /* background-image: url(../../assets/images/speakwithBackground.png); */
  /* background-size: cover;
  background-position: center;
  width: 100%; */
  /* height: 652px; */
  position: relative;
}
.speakWith::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.48);
  display: block;
  z-index: 2;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}

.speakWithText {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  z-index: 4;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  color: #ffffff;
}

.speakWithHeading {
  font-size: 40px;
  margin-bottom: 15px;
  font-weight: 700;
  text-transform: uppercase;
}

.speakWithPara {
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 20px;
}

.likeBtn {
  border: none;
  background-color: transparent;
}

.droneCards {
  margin: 40px 40px 80px;
  text-align: center;
  /* display: flex; */
  /* justify-content: space-around; */
  /* gap: 20px; */
  & .cardBtn{
    padding: 5px 20px;
  }
}
.droneCardListing{
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
}
.droneCard {
  /* border: 1px solid #ccc; */
  /* box-shadow: 2px 2px 2 rgba(0,0,0,0.12); */
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.12), -2px -2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  margin: 10px;
  box-sizing: border-box;
  padding: 20px 10px;
  width: 28%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
}
.droneLikeShare {
  text-align: right;
  display: none;
}
.droneImg {
  text-align: center;
  width: 100%;
  position: relative;
}

.overviewtext{
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.25);
  color: #ffffff;
  padding: 0 10px;
  font-size: 12px;
}
.droneImg > img {
  transition: 0.7s ease-in-out;
  width: 100%;
}

.droneImg > img:hover {
  transform: scale(1.1);
}
.droneName {
  font-size: 24px;
  font-weight: 700;
  color: rgba(96, 96, 96, 1);
  margin: 0 0 10px;
}

.dronePrice {
  font-size: 20px;
  font-weight: 500;
  color: rgba(96, 96, 96, 1);
  margin: 0 0 10px;
}

.droneCard > span {
  font-size: 13px;
  font-weight: 500;
  color: rgba(96, 96, 96, 1);
  margin: 0 0 10px;
}

.droneColorBar {
  margin: 10px 0;
  display: flex;
  gap: 3px;
  display: none;
}

.droneColor {
  width: 25px;
  height: 25px;
  border: 1px solid #ccc;
  border-radius: 50px;
  /* background-color: red; */
}
.excellenceCenterContainer {
  margin: 150px 40px;
  text-align: center;
}
.excellenceCenterCards {
  margin: 40px 0 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  align-items: flex-end;
}
.sectionHeading {
  font-size: 40px;
  font-weight: 700;
  color: #000000;
  margin: 10px 0;
}
.sectionPara {
  display: flex;
  justify-content: center;
}
.sectionPara > p {
  width: 60%;
  /* margin: 20px; */
  margin-bottom: 40px;
  font-size: 20px;
  font-weight: 400;
  color: #000000;
}

.excellenceCenterCard > p {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: #000000;
}

.rentContainer{
  margin: 150px 40px;
  text-align: center;
}
.rentingCards{
  /* width: 70%; */
  margin: 40px 0 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-around;
}
.rentingCard > p{
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: #000000;
}
.slickstyles{
  width: 100%;
}


/* dron as services */
.droneAsaService{
  text-align: center;
  margin: 40px 40px 80px;
}
.ServiceListings{
  width: 100%;
  margin-top: 40px;
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
  .bannerBackground {
    height: 702px;
  }
  .bannerText {
    width: 90%;
    bottom: 5%;
    top: auto;
    left: 10px;
    right: 0;
    /* transform: translate(50%,50%);? */
    color: #216fb9;
    text-align: left;
  }

  .bannerWelcome {
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    color: #ffffff;
  }

  .bannerHeading {
    font-size: 40px;
    font-weight: 700;
    margin: 0;
    color: #ffffff;
  }

  .bannerPara {
    font-size: 20px;
    font-weight: 700;
    color: #ffffff;
  }
  .downArrow {
    display: none;
    margin-top: 20px;
  }

  .ourServises{
    margin: 20px 20px 40px;
    text-align: center;
 }
  .cardSection {
    flex-direction: column;
    margin: 40px 10px;
  }
  .card{
    width: 100%;
  }
  .cardTitle {
    font-size: 16px;
  }
  .cardText {
    font-size: 14px;
  }
  .speakWith {
    height: 214px;
  }
  .speakWith::after {
    height: 214px;
    background-color: rgba(0, 0, 0, 0.50);
  }

  .cardBtn {
    font-size: 14px;
    padding: 13px 40px;
  }

  .speakWithText {
    width: 90%;
  }

  .speakWithHeading {
    font-size: 12px;
  }

  .speakWithPara {
    font-size: 14px;
  }
  .droneCards {
    margin: 20px;
    overflow: scroll;
    justify-content: initial;
    /* width: 950px; */
  }
  .droneCard {
    width: 320px;
  }
  .knowmoreforcard{
    font-size: 14px;
    font-weight: 500;
   
  }
  .droneName{
    font-size: 18px;
  }
  .droneImg {
    width: 280px;
  }
  .excellenceCenterContainer {
    margin: 40px 20px;
    text-align: center;
  }
  .sectionHeading {
    font-size: 22px;
  }
  .sectionPara {
    display: flex;
    justify-content: center;
  }
  .sectionPara > p {
    width: 100%;
    margin: 10px 0 20px;
    font-size: 14px;
  }
  .excellenceCenterCard {
    width: 150px;
  }
  .excellenceCenterImg {
    width: 100%;
  }
  .excellenceCenterImg > img {
    width: 100%;
  }
  .excellenceCenterCard > p {
    font-size: 14px;
  }

  .rentContainer{
    margin: 40px 20px;
  }
  .rentingCards{
    width: 100%;
    margin: 40px 0 0;
    display: flex;
    flex-wrap: nowrap;
    gap: 40px;
    /* overflow: auto; */
    justify-content: unset;
  }
  .rentingCard{
    width: calc(100% /3);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .rentingCard > p{
    font-size: 14px;
  }
  .rentingImg{
    width: 65px;
  }
  .rentingImg > img{
    width: 100%;
  }

  .droneCardListing{
    margin-top: 20px;
  }

  /* dron as services */
.droneAsaService{
  text-align: center;
  margin: 20px 20px 40px;
}
.ServiceListings{
  width: 100%;
  margin-top: 40px;
}
}
