.container {
  border: 2px solid #216fb9;
  border-radius: 18px;
  width: 325px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 110px;
  
}

.img {
  position: absolute;
  left: 20px;
  background-color: #216fb9;
  border: 4px solid #ffffff;
  border-radius: 999px;
  padding: 20px;
  bottom: -40px;
  & img{
    width: 100%;
  }
}
.container {
  & p {
    font-size: 22px;
    font-weight: 600;
    color: #216fb9;
  }

  & span {
    font-size: 16px;
    font-weight: 500;
    color: #6e7898;
  }
}
@media screen and (max-width: 768px) and (min-width: 320px) {
 .container{
    margin: 50px auto;
    width: 170px;
 }


 .img{
    bottom: -30px;
    left: 16px;
    padding: 10px ;
    box-sizing: border-box;
 }
}