.container {
  position: relative;
  margin: 50px auto;
  width: 80%;
}
.img {
  border-radius: 10px;
  & img {
    width: 100%;
    border-radius: 10px;
  }
}
.desc {
  position: absolute;
  background-color: #000000cc;
  color: #ffffff;
  bottom: 0px;
  width: 100%;
  text-align: center;
  padding: 20px 0px;
  border-radius: 0 0 10px 10px;

  & h3{
    margin: 0px;
  }
}
@media screen and (max-width: 768px) and (min-width: 320px) {
 .container{
    width: 95%;
 }

}