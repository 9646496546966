.mainContainer{
    /* width: 80%; */
    margin: 0 auto;
}
.backButton{
    width: 20px;
}
.backButton img{
    width: 100%;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
    .mainContainer{
        width: 100%;
        /* padding:0 20px; */
    }

    .backButton{
      
    }  
}