.container {
  width: 45%;
}

.desc {
  font-size: 14px;
  font-weight: 500;
  color: #6e7898;
}

.bateryHead {
  font-size: 24px;
  font-weight: 600;
  color: #2c2c2c;
}
.imgCon {
  display: flex;
  gap: 15px;
  margin: 20px auto;
  height: 330px;
}
.leftImg {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
}
.leftImg::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 768px) and (min-width: 320px) {
  .container {
    width: 100%;
  }
  .bateryHead {
    font-size: 16px;
  }
  .desc {
    font-size: 14px;
  }
  .imgCon {
    flex-direction: column-reverse;
    height: auto;
  }
  .leftImg {
    flex-direction: row;
  }
  .images {
    width: 170px;
 
}
  .rightImg {
    & img {
      width: 100%;
    }
  }
  
}
